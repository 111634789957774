import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_SYSTEM_OPTIONS = 'getSystemOptions'
export const GET_SYSTEM_OPTION_BY_ID = 'getSystemOptionById'
export const CREATE_SYSTEM_OPTIONS = 'createSystemOptions'
export const UPDATE_SYSTEM_OPTIONS = 'updateSystemOptions'
export const DELETE_SYSTEM_OPTIONS = 'deleteSystemOptions'

export const SET_SYSTEM_OPTIONS_LIST = 'setSystemOptionsList'
export const SET_SYSTEM_OPTION = 'setSystemOption'

export const GET_SYSTEM_OPTIONS_ME = 'getSystemOptionsMe'
export const SET_SYSTEM_OPTIONS_ME = 'setSystemOptionsMe'

export default {
  namespaced: true,
  state: {
    systemOptionsList: null,
    systemOption: null,
    systemOptionsMe: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.systemOptionsList
      if (list) {
        return list
      }
      return []
    },
    activeSystemOptionList: state => {
      const currentState = state.systemOptionsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },

    getSelectedSystemOption: state => {
      const currentState = state.systemOption

      if (currentState) {
        const list = currentState.list.data
        if (list) {
          return list
        }
        return {}
      }
      return {}
    },
    systemOptionsMe: state => {
      const currentState = state.systemOptionsMe

      if (currentState) {
        return currentState
      }
      return []
    },
  },
  mutations: {
    [SET_SYSTEM_OPTIONS_LIST](state, val) {
      state.systemOptionsList = val
    },
    [SET_SYSTEM_OPTION](state, val) {
      state.systemOption = val
    },
    [SET_SYSTEM_OPTIONS_ME](state, val) {
      state.systemOptionsMe = val
    },
  },
  actions: {
    [GET_SYSTEM_OPTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SYSTEM_OPTIONS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SYSTEM_OPTION_BY_ID](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SYSTEM_OPTION, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_SYSTEM_OPTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SYSTEM_OPTIONS_ME](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options/admin/me`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log('res', res.data.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SYSTEM_OPTIONS_ME, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_SYSTEM_OPTIONS](context, payload) {
      const { payloadId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options/${payloadId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_SYSTEM_OPTIONS](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
