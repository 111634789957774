import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import Router from '@/router'
import SystemHelper from '@/common/systemHelper'

export const GET_ACCESS_TOKEN = 'getAccessToken'
export const SET_ACCESS_TOKEN = 'setAccessToken'

export const LOGOUT = 'logout'

// export const GET_MERCHANT_ACCESS_TOKEN = 'getMerchantAccessToken'
// export const SET_MERCHANT_ACCESS_TOKEN = 'setMerchantAccessToken'
export const GET_ADMIN_ACCESS_TOKEN = 'getAdminAccessToken'
export const SET_ADMIN_ACCESS_TOKEN = 'setAdminAccessToken'

export const GET_WHITELABEL_ACCESS_TOKEN = 'getWhitelabelAccessToken'
export const SET_WHITELABEL_ACCESS_TOKEN = 'setWhitelabelAccessToken'

export const PURGE_AUTH = 'purgeAuth'

export const TOGGLE_DEBUG = 'toggleDebug'
export const SET_DEBUG_TOGGLE = 'setDebugToggle'

export default {
  namespaced: true,
  state: {
    debugToggle: false,
    accessToken: null,
    adminAccessToken: null,
  },
  getters: {
    getDebugToggle: state => state.debugToggle,
  },
  mutations: {
    [SET_DEBUG_TOGGLE](state, val) {
      state.debugToggle = val
    },
    [SET_ACCESS_TOKEN](state, val) {
      state.accessToken = val
    },
    [SET_ADMIN_ACCESS_TOKEN](state, val) {
      state.adminAccessToken = val
    },
    [PURGE_AUTH](state) {
      state.accessToken = null
      state.adminAccessToken = null
      console.log('purgeAuth')
      useJwt.destroyToken()
      Router.replace('/login')
    },
  },
  actions: {
    [TOGGLE_DEBUG](context, payload) {
      context.commit(SET_DEBUG_TOGGLE, payload)
    },
    [LOGOUT](context) {
      context.commit(PURGE_AUTH)
    },
    [GET_ADMIN_ACCESS_TOKEN](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}auth/access-token-viu`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.data.access_token)
              context.commit(SET_ADMIN_ACCESS_TOKEN, res.data.data.access_token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            console.log(err)
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCESS_TOKEN](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}auth/access-token-viu`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.data.access_token)
              context.commit(SET_ACCESS_TOKEN, res.data.data.access_token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            console.log(err)
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_WHITELABEL_ACCESS_TOKEN](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}auth/white-label-access-token`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.data.access_token)
              context.commit(SET_ACCESS_TOKEN, res.data.data.access_token)
              resolve(res)
            } else {
              alert(res.response_code)
              resolve(res)
            }
          })
          .catch(err => {
            console.log(err)
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
