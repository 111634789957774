import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_MERCHANT_SUCCESS_FIELD = 'getMerchantSuccessField'
export const SET_MERCHANT_SUCCESS_FIELD = 'SetMerchantSuccessField'

export const GET_MERCHANT_SUPPORT_DETAILS = 'getMerchantSupportDetails'
export const SET_MERCHANT_SUPPORT_DETAILS = 'setMerchantSupportDetails'

export const GET_MERCHANT_PAYMENT_SETTINGS = 'getMerchantPaymentSettings'
export const SET_MERCHANT_PAYMENT_SETTINGS = 'setMerchantPaymentSettings'

export const GET_MERCHANT_SHARE_SETTINGS = 'getMerchantShareSettings'
export const SET_MERCHANT_SHARE_SETTINGS = 'setMerchantShareSettings'

export const GET_MERCHANT_CATALOGS = 'getMerchantCatalogs'
export const SET_MERCHANT_CATALOGS = 'setMerchantCatalogs'

export const GET_MERCHANT_COLLECTIONS = 'getMerchantCollections'
export const SET_MERCHANT_COLLECTIONS_ACTIVE = 'setMerchantCollectionsActive'
export const SET_MERCHANT_COLLECTIONS_INACTIVE = 'setMerchantCollectionsInactive'
export const SET_MERCHANT_COLLECTIONS_ALL = 'setMerchantCollectionsAll'

export const GET_MERCHANT_CUSTOMERS = 'getMerchantCustomers'
export const SET_MERCHANT_CUSTOMERS = 'setMerchantCustomers'

export const GET_MERCHANT_CUSTOMER_BILLS_LIST = 'getMerchantCustomerBillsList'
export const SET_MERCHANT_CUSTOMER_BILLS_LIST = 'setMerchantCustomerBillsList'

export const GET_MERCHANT_DISCOUNTS = 'getMerchantDiscounts'
export const SET_MERCHANT_DISCOUNTS = 'setMerchantDiscounts'

export const GET_MERCHANT_PRODUCTS = 'getMerchantProducts'
export const SET_MERCHANT_PRODUCTS = 'setMerchantProducts'

export const GET_MERCHANT_PAYMENT_RECORDS = 'getMerchantPaymentRecords'
export const SET_MERCHANT_PAYMENT_RECORDS = 'setMerchantPaymentRecords'

export const GET_MERCHANT_PRODUCT_CATEGORIES = 'getMerchantProductCategories'
export const SET_MERCHANT_PRODUCT_CATEGORIES = 'setMerchantProductCategories'

export const GET_SPECIFIC_CUSTOMER_BILLS = 'getSpecificCustomerBillsList'
export const SET_SPECIFIC_CUSTOMER_BILLS = 'setSpecificCustomerBillsList'

export const GET_MERCHANT_SYSTEM_OPTIONS = 'getMerchantSystemOptions'
export const SET_MERCHANT_SYSTEM_OPTIONS = 'setMerchantSystemOptions'

export const GET_MERCHANT_PAYMENT_UPLOADS = 'getMerchantPaymentUploads'
export const SET_MERCHANT_PAYMENT_UPLOADS = 'setMerchantPaymentUploads'

export const GET_BILLS_LIST_BY_COLLECTION_ID = 'getBillsListByCollectionId'
export const SET_BILLS_LIST_BY_COLLECTION_ID = 'setBillsListByCollectionId'

export default {
  namespaced: true,
  state: {
    merchantSuccessField: null,
    merchantSupportDetails: null,
    merchantPaymentSettings: null,
    merchantShareSettings: null,
    merchantCatalogs: null,
    merchantCollections: {
      all: null,
      active: null,
      inactive: null,
    },
    merchantCustomers: null,
    merchantCustomersBill: null,
    merchantCustomerBillsList: null,
    merchantDiscounts: null,
    merchantProducts: null,
    merchantProductCategories: null,
    specificCustomerBillsList: null,
    merchantPaymentRecords: null,
    merchantSystemOptions: null,
    merchantPaymentUploads: null,
    merchantBillsByCollectionId: null,
  },
  getters: {
    getPaginationInfoByStateKey: state => stateKey => {
      console.log(state[stateKey])
      const list = state[stateKey]
      if (list) {
        return list.list
      }
      return []
    },
    getMerchantSuccessField: state => {
      const list = state.merchantSuccessField
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantShareSettings: state => {
      const list = state.merchantShareSettings
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantSupportDetails: state => {
      const list = state.merchantSupportDetails
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantPaymentSettings: state => {
      const list = state.merchantPaymentSettings
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantCatalogs: state => {
      const list = state.merchantCatalogs
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantCollection: state => type => {
      let list = state.merchantCollections
      if (type === 'inactive') {
        list = list.inactive
      } else if (type === 'active') {
        list = list.active
      } else {
        list = list.all
      }
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantCustomer: state => {
      const list = state.merchantCustomers
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantCustomers: state => {
      const list = state.merchantCustomers
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantSystemOptions: state => {
      const list = state.merchantSystemOptions
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantPaymentRecords: state => {
      const list = state.merchantPaymentRecords
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantProducts: state => {
      const list = state.merchantProducts
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantProductsOpts: state => {
      const list = state.merchantProducts

      const opts = list

      console.log('opts', opts)
      if (opts) {
        console.log('opts', opts.list.data)
        if (Array.isArray(opts.list.data) && opts.list.data.length) {
          const newArray = opts.list.data.map(item => ({ product_id: item.id, name: item.title, amount: item.price }))
          console.log('newArray', newArray)

          return newArray
        }
      }
      return []
    },
    getMerchantProductCategories: state => {
      const list = state.merchantProductCategories
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantProductCategoriesOpts: state => {
      const list = state.merchantProductCategories
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantCustomerBills: state => {
      const list = state.merchantCustomersBill
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantCustomerBillsList: state => {
      const list = state.merchantCustomerBillsList
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantPaymentUploads: state => {
      const list = state.merchantPaymentUploads
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantDiscounts: state => {
      const list = state.merchantDiscounts
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantBillsByCollectionId: state => {
      const list = state.merchantBillsByCollectionId
      // alert(JSON.stringify(list))
      if (list) {
        return list.list.data
      }
      return []
    },
    specificCustomerBillsList: state => {
      const currentState = state.specificCustomerBillsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_MERCHANT_SUCCESS_FIELD](state, val) {
      state.merchantSuccessField = val
    },
    [SET_MERCHANT_SUPPORT_DETAILS](state, val) {
      state.merchantSupportDetails = val
    },
    [SET_MERCHANT_PAYMENT_SETTINGS](state, val) {
      state.merchantPaymentSettings = val
    },
    [SET_MERCHANT_SHARE_SETTINGS](state, val) {
      state.merchantShareSettings = val
    },
    [SET_MERCHANT_CATALOGS](state, val) {
      state.merchantCatalogs = val
    },
    [SET_MERCHANT_COLLECTIONS_ALL](state, val) {
      state.merchantCollections.all = val
    },
    [SET_MERCHANT_COLLECTIONS_ACTIVE](state, val) {
      state.merchantCollections.active = val
    },
    [SET_MERCHANT_COLLECTIONS_INACTIVE](state, val) {
      state.merchantCollections.inactive = val
    },
    [SET_MERCHANT_CUSTOMERS](state, val) {
      state.merchantCustomers = val
    },
    [SET_MERCHANT_PRODUCTS](state, val) {
      state.merchantProducts = val
    },
    [SET_MERCHANT_PRODUCT_CATEGORIES](state, val) {
      state.merchantProductCategories = val
    },
    [SET_MERCHANT_CUSTOMER_BILLS_LIST](state, val) {
      state.merchantCustomerBillsList = val
    },
    [SET_SPECIFIC_CUSTOMER_BILLS](state, val) {
      state.specificCustomerBillsList = val
    },
    [SET_MERCHANT_PAYMENT_RECORDS](state, val) {
      state.merchantPaymentRecords = val
    },
    [SET_MERCHANT_SYSTEM_OPTIONS](state, val) {
      state.merchantSystemOptions = val
    },
    [SET_MERCHANT_PAYMENT_UPLOADS](state, val) {
      state.merchantPaymentUploads = val
    },
    [SET_BILLS_LIST_BY_COLLECTION_ID](state, val) {
      state.merchantBillsByCollectionId = val
    },
  },
  actions: {

    [GET_MERCHANT_CUSTOMER_BILLS_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/merchant-customer-bill?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              console.log('getMerchantCustomerBillsList', res.data.data)
              context.commit(SET_MERCHANT_CUSTOMER_BILLS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYMENT_UPLOADS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-upload-files/merchant-upload-bill-excel?skip=${payload.skip}&limit=${payload.limit}&collection_id=${payload.collection_id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              console.log('GET_MERCHANT_PAYMENT_UPLOADS', res.data.data)
              context.commit(SET_MERCHANT_PAYMENT_UPLOADS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SYSTEM_OPTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options/merchant-system-options?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              console.log('GET_MERCHANT_SYSTEM_OPTIONS', res.data.data)
              context.commit(SET_MERCHANT_SYSTEM_OPTIONS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_CUSTOMERS](context, payload) {
      console.log('getMerchantCustomers')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customers/merchant-customer?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('getMerchantCustomers', res.data.data)
              context.commit(SET_MERCHANT_CUSTOMERS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYMENT_RECORDS](context, payload) {
      console.log('GET_MERCHANT_PAYMENT_RECORDS')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/merchant-payment-records?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('SET_MERCHANT_PAYMENT_RECORDS', res.data.data)
              context.commit(SET_MERCHANT_PAYMENT_RECORDS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_SPECIFIC_CUSTOMER_BILLS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/get-based-customer/?customer_id=${payload.id}&skip=${payload.skip}&limit=${payload.limit}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SPECIFIC_CUSTOMER_BILLS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_BILLS_LIST_BY_COLLECTION_ID](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/get-based-collection/${payload.id}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_BILLS_LIST_BY_COLLECTION_ID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_DISCOUNTS](context, payload) {
      console.log('getMerchantDiscounts')
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}discounts/merchant-discount?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('getMerchantDiscounts', res.data.data)
              context.commit(SET_MERCHANT_DISCOUNTS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PRODUCTS](context, payload) {
      console.log('GET_MERCHANT_PRODUCTS')
      console.log('GET_MERCHANT_PRODUCTS', payload)
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}products/merchant-product?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('SET_MERCHANT_PRODUCTS', res.data.data)
              context.commit(SET_MERCHANT_PRODUCTS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PRODUCT_CATEGORIES](context, payload) {
      console.log('GET_MERCHANT_PRODUCT_CATEGORIES')
      console.log('GET_MERCHANT_PRODUCT_CATEGORIES', payload)
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-categories/merchant-product-category?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('SET_MERCHANT_PRODUCT_CATEGORIES', res.data.data)
              context.commit(SET_MERCHANT_PRODUCT_CATEGORIES, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_COLLECTIONS](context, payload) {
      console.log('getMerchantCollections')
      const { listType } = payload
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/merchant-collection/?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('getMerchantCollections', res.data.data)
              if (listType === 'active') {
                context.commit(SET_MERCHANT_COLLECTIONS_ACTIVE, res.data.data)
              } if (listType === 'inactive') {
                context.commit(SET_MERCHANT_COLLECTIONS_INACTIVE, res.data.data)
              } else {
                context.commit(SET_MERCHANT_COLLECTIONS_ALL, res.data.data)
              }
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_CATALOGS](context, payload) {
      console.log('getMerchantCatalogs')
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}catalogs/merchant-catalogs?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('getMerchantCatalogs', res.data.data)
              context.commit(SET_MERCHANT_CATALOGS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SUCCESS_FIELD](context, payload) {
      console.log('getMerchantSuccessField')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}success-field/merchant-success-fields?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('getMerchantSuccessField', res.data.data)
              context.commit(SET_MERCHANT_SUCCESS_FIELD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SUPPORT_DETAILS](context, payload) {
      console.log('merchant-support-details')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}support-details/merchant-support-details?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('merchant-support-details', res.data.data)
              context.commit(SET_MERCHANT_SUPPORT_DETAILS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SYSTEM_OPTIONS](context, payload) {
      console.log('GET_MERCHANT_SYSTEM_OPTIONS')
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options/merchant-system-options?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('GET_MERCHANT_SYSTEM_OPTIONS', res.data.data)
              context.commit(SET_MERCHANT_SYSTEM_OPTIONS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYMENT_SETTINGS](context, payload) {
      console.log('merchant-payment-settings')
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-settings/merchant-payment-settings?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('merchant-payment-settings', res.data.data)
              context.commit(SET_MERCHANT_PAYMENT_SETTINGS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SHARE_SETTINGS](context, payload) {
      console.log('merchant-share-settings')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}share-settings/merchant-share-settings?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('merchant-share-settings', res.data.data)
              context.commit(SET_MERCHANT_SHARE_SETTINGS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
