import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_ADMIN_VIRTUAL_ACCOUNT_DETAILS = 'getAdminVirtualAccountDetails'
export const SET_ADMIN_VIRTUAL_ACCOUNT_DETAILS = 'setAdminVirtualAccountDetails'

export default {
  namespaced: true,
  state: {
    virtualAccountDetails: null,
  },
  getters: {
    getAdminVirtualAccountDetails: state => {
      const currentState = state.virtualAccountDetails
      if (currentState) {
        return currentState
      } return []
    },
  },
  mutations: {
    [SET_ADMIN_VIRTUAL_ACCOUNT_DETAILS](state, val) {
      state.virtualAccountDetails = val
    },
  },
  actions: {
    [GET_ADMIN_VIRTUAL_ACCOUNT_DETAILS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}virtual-accounts/admin/virtual-account-details`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_VIRTUAL_ACCOUNT_DETAILS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
