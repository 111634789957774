import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const UPLOAD_CUSTOMER_BILLS_EXCEL = 'uploadCustomerBillsExcel'

export const GET_MERCHANT_PAYMENT_UPLOADS = 'getMerchantPaymentUploads'
export const SET_MERCHANT_PAYMENT_UPLOADS = 'setMerchantPaymentUploads'

export default {
  namespaced: true,
  state: {
    paymentSettingsList: null,
    paymentSetting: null,
    merchantPaymentUploads: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfoByStateKey: state => stateKey => {
      console.log('here', state[stateKey])
      console.log('here', state)
      console.log('here', stateKey)

      const list = state[stateKey]
      if (list) {
        return list.list
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.paymentSettingsList
      if (list) {
        return list
      }
      return []
    },
    getMerchantPaymentUploads: state => {
      const list = state.merchantPaymentUploads
      if (list) {
        return list.list.data
      }
      return []
    },
  },
  mutations: {
    [SET_MERCHANT_PAYMENT_UPLOADS](state, val) {
      state.merchantPaymentUploads = val
    },
  },
  actions: {
    [GET_MERCHANT_PAYMENT_UPLOADS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-upload-files/merchant-upload-bill-excel?skip=${payload.skip}&limit=${payload.limit}&collection_id=${payload.collection_id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              console.log('GET_MERCHANT_PAYMENT_UPLOADS', res.data.data)
              context.commit(SET_MERCHANT_PAYMENT_UPLOADS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPLOAD_CUSTOMER_BILLS_EXCEL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-upload-files/upload-customer-bills-excel`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
