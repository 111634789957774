import Vue from 'vue'
import { BootstrapVue, ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import VueNumeric from 'vue-numeric'
import { $themeConfig } from '@themeConfig'
import DOMPurify from 'isomorphic-dompurify'
import router from './router'
import store from './store'
import App from './App.vue'
import mixins from '@/common/mixins'

import * as My2c2p from '@/assets/js/my2c2p-sandbox.1.7.3.min'
import '@/libs/sweet-alerts'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'

import ImageFallBack from '@/common/imageFallback.directive'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@validations'

import mixin from '@/common/mixin'

Vue.directive('image-fall-back', ImageFallBack)

Vue.use(VueNumeric)

console.log('MODE', process.env.NODE_ENV)
console.log('VUE_APP_TITLE', process.env.VUE_APP_TITLE)
console.log('VUE_APP_API_URL', process.env.VUE_APP_API_URL)

Vue.mixin(mixin)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.mixin({
  data() {
    return {
      IS_SANDBOX: process.env.VUE_APP_DEBUG_FLAG_NAME === 'sandbox',
      IS_DEVELOPMENT: process.env.VUE_APP_DEBUG_FLAG_NAME === 'development',
      IS_STAGING: process.env.VUE_APP_DEBUG_FLAG_NAME === 'staging',
      IS_PRODUCTION: process.env.VUE_APP_DEBUG_FLAG_NAME === 'production',

      IS_CRM_PORTAL: process.env.VUE_APP_DEBUG_IS_PORTAL === 'CRM',
      IS_ADMIN_PORTAL: process.env.VUE_APP_DEBUG_IS_PORTAL === 'ADMIN',

      defaultDateRange: {
        startDate: '01-01-1990',
        endDate: '01-01-2100',
      },
      myCurrency: 'MYR',
      logoImg: require('@/assets/leanpay/admin_logo.png'),
      debugMode: process.env.NODE_ENV === 'development', // reverse this flag back
      globalCurrency: 'MYR',
      swalDefault: {
        showCancelButton: true,
        customClass: {
          container: 'confirmation-swal',
          header: 'bg-primary',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      },
    }
  },
  computed: {
    perPageOptions() {
      return [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 250, text: '250' },
        { value: 500, text: '500' },
        { value: 1000, text: '1000' },
      ]
    },
    getThemeConfig() {
      return $themeConfig
    },
  },
  methods: {

    async toggleCheckBankModal(payload = null) {
      const statusStore = await this.$store.getters['systemConstants/getCheckBankAccountModalStatus']
      const newStatus = !statusStore

      console.log(payload)
      let newPayload = {}
      if (payload) {
        if (payload.third_party_account_no) {
          newPayload = {
            ...newPayload,
            third_party_account_no: payload.third_party_account_no,
          }
        }
        if (payload.verification_channel_id) {
          newPayload = {
            ...newPayload,
            verification_channel_id: payload.verification_channel_id,
          }
        }

        await this.$store.dispatch('general/updateVerificationPayload', newPayload)
      }

      await this.$store.dispatch('systemConstants/toggleCheckBankAccountModal', newStatus)
    },
    traverse(uuid) {
      this.$router.push({ name: 'administration-settlement-datatable-details', params: { uuid } })
    },
    purify(text) {
      return `${DOMPurify.sanitize(text)}`
    },
    doCopy(elem, elementName = null) {
      this.$copyText(elem).then(() => {
        let label
        if (elementName) {
          label = elementName
        } else {
          label = 'Text'
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${label} copied`,
            icon: 'BellIcon',
          },
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
    displaySystemConstantsLabel(key, id = null) {
      const recordStatusList = this.$store.getters['systemConstants/getSystemConstantsByKey'](key)

      if (Array.isArray(recordStatusList) && recordStatusList.length && id !== null) {
        const x = recordStatusList.filter(el => el.value === id)
        return this.humanise(
          x[0].name,
        )
      }
      return 'NA'
    },
  },
})

Vue.prototype.My2c2p = My2c2p

// eslint-disable-next-line import/prefer-default-export
export const vm = new Vue({
  router,
  store,
  mixins: [mixins],
  render: h => h(App),
}).$mount('#app')
