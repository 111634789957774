import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_CUSTOMER_BILLS = 'getCustomerBills'
export const GET_CUSTOMER_BILLS_BY_ID = 'getCustomerBillsById'
export const CREATE_CUSTOMER_BILL_BILL_METHOD = 'createCustomerBillBillMethod'
export const GET_CUSTOMER_BILLS_BY_COLLECTION_ID = 'getCustomerBillsByCollectionId'

export const SET_CUSTOMER_BILLS = 'setCustomerBillsList'
export const SET_CUSTOMER_BILLS_BY_COLLECTION_ID = 'setCustomerBillsByCollectionId'
export const SET_CUSTOMER_BILL = 'setCustomerBill'

export default {
  namespaced: true,
  state: {
    customerBillsList: null,
    customerBillsByCollectionId: null,
    customerBill: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfoByStateKey: state => stateKey => {
      console.log('here', state[stateKey])
      console.log('here', state)
      console.log('here', stateKey)

      const list = state[stateKey]
      if (list) {
        return list.list
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.customerBillsList
      if (list) {
        return list
      }
      return []
    },

    specificCustomerBillsList: state => {
      const currentState = state.specificCustomerBillsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    activeCustomerBillsList: state => {
      const currentState = state.customerBillsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    customerBillsList: state => {
      const currentState = state.customerBillsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    customerBillsByCollectionIdList: state => {
      const currentState = state.customerBillsByCollectionId

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getCustomerBill: state => {
      const currentState = state.customerBill
      console.log('getCustomerBill', currentState)
      if (currentState) {
        return currentState
      }
      return {}
    },
  },
  mutations: {
    [SET_CUSTOMER_BILLS](state, val) {
      state.customerBillsList = val
    },
    [SET_CUSTOMER_BILL](state, val) {
      state.customerBill = val
    },
    [SET_CUSTOMER_BILLS_BY_COLLECTION_ID](state, val) {
      state.customerBillsByCollectionId = val
    },
  },
  actions: {
    [GET_CUSTOMER_BILLS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CUSTOMER_BILLS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_CUSTOMER_BILL_BILL_METHOD](context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/customer-bill-method`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_CUSTOMER_BILLS_BY_ID](context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/${payload.collection_id}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log('-------------')
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CUSTOMER_BILL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_CUSTOMER_BILLS_BY_COLLECTION_ID](context, payload) {
      console.log(payload)
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/get-based-collection/${payload.collection_id}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log('-------------')
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CUSTOMER_BILLS_BY_COLLECTION_ID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
