import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_EXCLUSION_PLAN_RULES = 'getExclusionPlanRules'
export const UPDATE_EXCLUSION_RULES = 'updateExclusionPlanRules'
export const SET_EXCLUSION_PLAN_RULES = 'setExclusionPlanRules'

export const GET_EXCLUSION_RESOURCE = 'getExclusionResource'
export const SET_EXCLUSION_RESOURCE = 'setExclusionResource'
export const GET_PAYMENT_RATE_BY_PLAN_NAME = 'getPaymentRateByPlanName'
export const UPDATE_PAYMENT_RATE = 'updatePaymentRate'
export const SET_PAYMENT_RATE_BY_PLAN_NAME = 'setPaymentRateByPlanName'

export default {
  namespaced: true,
  state: {
    paymentRateByPlanName: null,
    exclusionPlanRules: null,
    exclusionResource: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaymentRateByPlanName: state => {
      const currentState = state.paymentRateByPlanName
      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getExclusionPlanRules: state => {
      const currentState = state.exclusionPlanRules
      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getExclusionResourceByKey: state => key => {
      const obj = state.exclusionResource[key]
      if (obj) {
        const list = obj
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getExclusionResource: state => (key, subkey) => {
      if (state.exclusionResource) {
        const obj = state.exclusionResource[key][subkey]
        if (obj) {
          return obj
        }
        return []
      } return []
    },
    getPaginationInfo: state => {
      const list = state.paymentRateByPlanName
      if (list) {
        return list
      }
      return []
    },

  },
  mutations: {
    [SET_PAYMENT_RATE_BY_PLAN_NAME](state, val) {
      state.paymentRateByPlanName = val
    },
    [SET_EXCLUSION_PLAN_RULES](state, val) {
      state.exclusionPlanRules = val
    },
    [SET_EXCLUSION_RESOURCE](state, val) {
      state.exclusionResource = val
    },
  },
  actions: {
    [GET_PAYMENT_RATE_BY_PLAN_NAME](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-rate/admin/get-plan-payment-rate?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_PAYMENT_RATE_BY_PLAN_NAME, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_PAYMENT_RATE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-rate/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_EXCLUSION_RULES](context, payload) {
      return new Promise((resolve, reject) => {
        /// api/v1/payment-exclusion-rule/{_id}
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-exclusion-rule/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_EXCLUSION_PLAN_RULES](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-exclusion-rule/admin/get-exclusion-plan-rules?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_EXCLUSION_PLAN_RULES, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_EXCLUSION_RESOURCE](context) {
      return new Promise((resolve, reject) => {
        // https://stag-api.leanpay.my/assets/json_file/exclude_payment.json
        axiosIns.get(`${APP_SETTINGS.BASE_URL}assets/json_file/exclude_payment.json`)
          .then(res => {
            // console.log(res)

            context.commit(SET_EXCLUSION_RESOURCE, res.data)
            // if (res) {
            //   //console.log(res.data)
            //   useJwt.setToken(res.data.token)
            //   context.commit(SET_EXCLUSION_RESOURCE, res)
            //   SystemHelper.successHandler(res)
            //   resolve(res)
            // } else {
            //   SystemHelper.errorHandler(res)
            //   resolve(res)
            // }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
