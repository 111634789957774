import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_ADMIN_COLLECTION_POOL_DETAILS = 'getAdminCollectionPoolDetails'
export const SET_ADMIN_COLLECTION_POOL_DETAILS = 'setAdminCollectionPoolDetails'
export const GET_MERCHANT_POOL_ACCOUNT_BALANCE = 'getMerchantPoolAccountBalance'
export const SET_MERCHANT_POOL_ACCOUNT_BALANCE = 'setMerchantPoolAccountBalance'

export default {
  namespaced: true,
  state: {
    adminCollectionPoolDetails: null,
    merchantPoolAccountBalance: null,
  },
  getters: {
    getAdminCollectionPoolDetails: state => {
      const currentState = state.adminCollectionPoolDetails
      if (currentState) {
        return currentState
      } return []
    },
    getMerchantPoolAccountBalance: state => {
      const currentState = state.merchantPoolAccountBalance
      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_ADMIN_COLLECTION_POOL_DETAILS](state, val) {
      state.adminCollectionPoolDetails = val
    },
    [SET_MERCHANT_POOL_ACCOUNT_BALANCE](state, val) {
      state.merchantPoolAccountBalance = val
    },
  },
  actions: {
    [GET_ADMIN_COLLECTION_POOL_DETAILS](context) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}pools/admin-collection-pool`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_COLLECTION_POOL_DETAILS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_POOL_ACCOUNT_BALANCE](context, payload) {
      const finalPayload = {

        ...payload,
      }

      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}pools/admin/merchant-list?skip=0&limit=100&_id=${finalPayload.merchantId}`, finalPayload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_POOL_ACCOUNT_BALANCE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
