import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_PAYMENT_RECORDS = 'getPaymentRecords'
export const GET_PAYMENT_RECORD_BY_ID = 'getPaymentRecordById'
export const CREATE_PAYMENT_RECORDS = 'createPaymentRecords'
export const UPDATE_PAYMENT_RECORDS = 'updatePaymentRecords'
export const DELETE_PAYMENT_RECORDS = 'deletePaymentRecords'
export const GET_PAYMENT_RECORDS_BY_BILL = 'getPaymentRecordsByBill'

export const SET_PAYMENT_RECORDS_LIST = 'setPaymentRecordsList'
export const SET_PAYMENT_RECORDS_BY_BILL = 'setPaymentRecordsByBill'
export const SET_PAYMENT_RECORD = 'setPaymentRecord'

export default {
  namespaced: true,
  state: {
    paymentRecordsList: null,
    paymentRecord: null,
    paymentRecordsByBill: null,
    customerBillsList: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfoByStateKey: state => stateKey => {
      console.log('here', state[stateKey])
      console.log('here', state)
      console.log('here', stateKey)

      const list = state[stateKey]
      if (list) {
        return list.list
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.paymentRecordsList
      if (list) {
        return list
      }
      return []
    },
    getCustomerBillsPaginationInfo: state => {
      const list = state.customerBillsList
      if (list) {
        return list
      }
      return []
    },
    activePaymentRecordList: state => {
      const currentState = state.paymentRecordsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },

    getSelectedPaymentRecord: state => {
      const currentState = state.paymentRecord

      if (currentState) {
        const list = currentState.list.data
        if (list) {
          return list
        }
        return {}
      }
      return {}
    },
    paymentRecordsList: state => {
      const currentState = state.paymentRecordsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getPaymentRecordsByBill: state => {
      const currentState = state.paymentRecordsByBill

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_PAYMENT_RECORDS_LIST](state, val) {
      state.paymentRecordsList = val
    },
    [SET_PAYMENT_RECORD](state, val) {
      state.paymentRecord = val
    },
    [SET_PAYMENT_RECORDS_BY_BILL](state, val) {
      state.paymentRecordsByBill = val
    },
  },
  actions: {
    [GET_PAYMENT_RECORDS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_PAYMENT_RECORDS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_PAYMENT_RECORDS_BY_BILL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/admin/bill-invoice/?skip=${payload.skip}&limit=${payload.limit}&bill_invoice=${payload.bill_invoice}&account_id=${payload.account_id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_PAYMENT_RECORDS_BY_BILL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_PAYMENT_RECORD_BY_ID](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_PAYMENT_RECORD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_PAYMENT_RECORDS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_PAYMENT_RECORDS](context, payload) {
      const { payloadId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/${payloadId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_PAYMENT_RECORDS](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
