import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_ADMIN_PREFUND_RECORDS = 'getAdminPrefundRecords'
export const UPDATE_ADMIN_PREFUND_RECORDS = 'updateAdminPrefundRecords'
export const SET_ADMIN_PREFUND_RECORDS = 'setAdminPrefundRecords'

export default {
  namespaced: true,
  state: {
    prefundRecordList: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfo: state => {
      const list = state.prefundRecordList
      if (list) {
        return list.list
      }
      return []
    },
    activePrefundRecordList: state => {
      const currentState = state.prefundRecordList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    getPrefundRecordList: state => {
      const currentState = state.prefundRecordList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_ADMIN_PREFUND_RECORDS](state, val) {
      state.prefundRecordList = val
    },
  },
  actions: {
    [GET_ADMIN_PREFUND_RECORDS](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}prefund-records/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_PREFUND_RECORDS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_ADMIN_PREFUND_RECORDS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}prefund-records/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
