import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_ADMIN_REFERRALS_LIST = 'getAdminReferralsList'
export const CREATE_REFERRAL = 'createReferral'
export const UPDATE_REFERRAL = 'updateReferral'
export const DELETE_REFERRAL = 'deleteReferral'
export const SET_ADMIN_REFERRALS_LIST = 'setAdminReferralsList'

export default {
  namespaced: true,
  state: {
    referralList: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },

    referralList: state => {
      const currentState = state.referralList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },

    getPaginationInfo: state => {
      const list = state.subscriptionsList
      if (list) {
        return list
      }
      return []
    },
  },
  mutations: {
    [SET_ADMIN_REFERRALS_LIST](state, val) {
      state.referralList = val
    },
  },
  actions: {
    [GET_ADMIN_REFERRALS_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}referral/admin/list`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_REFERRALS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_REFERRAL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}referral`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_REFERRAL](context, payload) {
      const { payloadId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}referral/${payloadId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_REFERRAL](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}referral/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
