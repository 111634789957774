import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_SUBSCRIPTIONS = 'getSubscriptions'
export const GET_SUBSCRIPTIONS_DETAILS_ME = 'getSubscriptionsDetailsMe'
export const GET_SUBSCRIPTION_BY_ID = 'getSubscriptionById'
export const GET_SUBSCRIPTION_PLAN_BY_ID = 'getSubscriptionPlanById'
export const CREATE_SUBSCRIPTIONS = 'createSubscriptions'
export const UPDATE_SUBSCRIPTIONS = 'updateSubscriptions'
export const ADMIN_UPGRADE_SUBSCRIPTION = 'adminUpgradeSubscription'
export const ADMIN_UPGRADE_SUBSCRIPTION_BY_REFERRAL_KEY = 'adminUpgradeSubscriptionByReferralKey'
export const DELETE_SUBSCRIPTIONS = 'deleteSubscriptions'

export const SET_SUBSCRIPTIONS_LIST = 'setSubscriptionsList'
export const SET_SUBSCRIPTIONS_DETAILS_ME = 'setSubscriptionsDetailsMe'
export const SET_SUBSCRIPTION = 'setSubscription'

export default {
  namespaced: true,
  state: {
    subscriptionsList: null,
    subscriptionDetailsMe: null,
    subscription: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getTemplateList: state => {
      const currentState = state.subscriptionsList
      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          if (process.env.VUE_APP_DEBUG_FLAG_NAME === 'development') {
            return list.filter(el => el.id === 1 || el.id === 2 || el.id === 3)
          }
          if (process.env.VUE_APP_DEBUG_FLAG_NAME === 'staging') {
            return list.filter(el => el.id === 1 || el.id === 2 || el.id === 3)
          }
          if (process.env.VUE_APP_DEBUG_FLAG_NAME === 'sandbox') {
            return list.filter(el => el.id === 1 || el.id === 2 || el.id === 3)
          }
          if (process.env.VUE_APP_DEBUG_FLAG_NAME === 'production') {
            return list.filter(el => el.id === 4 || el.id === 9 || el.id === 14)
          }
        }
        return []
      }
      return []
    },
    getSubscriptionPlanList: state => {
      const currentState = state.subscriptionsList
      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.subscriptionsList
      if (list) {
        return list
      }
      return []
    },
    activeSubscriptionList: state => {
      const currentState = state.subscriptionsList

      if (currentState) {
        const list = currentState.list.data
      }
      return []
    },

    getSelectedSubscription: state => {
      const currentState = state.subscription

      if (currentState) {
        const list = currentState
        if (list) {
          return list
        }
        return {}
      }
      return {}
    },
    subscriptionsList: state => {
      const currentState = state.subscriptionsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getMySubscriptionDetails: state => {
      const currentState = state.subscriptionDetailsMe

      if (currentState) {
        return currentState
      }
      return []
    },
  },
  mutations: {
    [SET_SUBSCRIPTIONS_LIST](state, val) {
      state.subscriptionsList = val
    },
    [SET_SUBSCRIPTION](state, val) {
      state.subscription = val
    },
    [SET_SUBSCRIPTIONS_DETAILS_ME](state, val) {
      state.subscriptionDetailsMe = val
    },
  },
  actions: {
    [GET_SUBSCRIPTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/admin/list`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SUBSCRIPTIONS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SUBSCRIPTIONS_DETAILS_ME](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/details/me`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SUBSCRIPTIONS_DETAILS_ME, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SUBSCRIPTION_PLAN_BY_ID](context, payload) {
      // console.log('GET_SUBSCRIPTION_PLAN_BY_ID')
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/${payload}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SUBSCRIPTION, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SUBSCRIPTION_BY_ID](context, payload) {
      // console.log('GET_SUBSCRIPTION_BY_ID')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/admin/subscriber/${payload}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SUBSCRIPTION, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_SUBSCRIPTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/create/subscriptions-with-rate`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_SUBSCRIPTIONS](context, payload) {
      const { payloadId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/${payloadId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [ADMIN_UPGRADE_SUBSCRIPTION](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories/upgrade-plan`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [ADMIN_UPGRADE_SUBSCRIPTION_BY_REFERRAL_KEY](context, payload) {
      return new Promise((resolve, reject) => {
        /// api/v1/subscription-histories/upgrade-plan-by-custom-plans
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories/upgrade-plan-by-referral`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_SUBSCRIPTIONS](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
