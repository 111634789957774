import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_AFFILIATE_LIST = 'getAffiliateList'
export const GET_AFFILIATE_RECORD_LIST = 'getAffiliateRecordList'
export const GET_AFFILIATE_ORDER_LIST = 'getAffiliateOrderList'
export const SET_AFFILIATE_ORDER_LIST = 'setAffiliateOrderList'
export const SET_AFFILIATE_RECORD_LIST = 'setAffiliateRecordList'
export const SET_AFFILIATE_LIST = 'setAffiliateList'
export const APPROVE_AFFILIATE_PAYOUT = 'approveAffiliatePayout'
export const CREATE_AFFILIATE_EXISTING_MERCHANT = 'createAffiliateExistingMerchant'

export const GET_AFFILIATE_DETAILS_BY_EMAIL = 'getAffiliateDetailsByEmail'
export const SET_AFFILIATE_DETAILS_BY_EMAIL = 'setAffiliateDetailsByEmail'

export const ASSIGN_POST_REFERRAL = 'assignPostReferral'

export default {
  namespaced: true,
  state: {
    affiliateList: null,
    affiliateRecordList: null,
    affiliateOrderList: null,
    affiliateDetail: null,
  },
  getters: {
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    affiliateList: state => {
      const currentState = state.affiliateList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getAffiliateRecordList: state => {
      const currentState = state.affiliateRecordList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    affiliateOrderList: state => {
      const currentState = state.affiliateOrderList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.affiliateList
      if (list) {
        return list
      }
      return []
    },
  },
  mutations: {
    [SET_AFFILIATE_LIST](state, val) {
      state.affiliateList = val
    },
    [SET_AFFILIATE_RECORD_LIST](state, val) {
      state.affiliateRecordList = val
    },
    [SET_AFFILIATE_DETAILS_BY_EMAIL](state, val) {
      state.affiliateDetail = val
    },
    [SET_AFFILIATE_ORDER_LIST](state, val) {
      state.affiliateOrderList = val
    },
  },
  actions: {
    [GET_AFFILIATE_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}affiliate/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_AFFILIATE_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_AFFILIATE_RECORD_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}affiliate-record/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_AFFILIATE_RECORD_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_AFFILIATE_DETAILS_BY_EMAIL](context, payload) {
      return new Promise((resolve, reject) => {
        // http://127.0.0.1:5049/api/v1/affiliate-recordadmin/assign-email-details?_email=syafiq%40gmail.com
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}affiliate-record/admin/assign-email-details?_email=${payload.email}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_AFFILIATE_DETAILS_BY_EMAIL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [ASSIGN_POST_REFERRAL](context, payload) {
      return new Promise((resolve, reject) => {
        // http://127.0.0.1:5049/api/v1/affiliate-record/admin/assign-post-referral
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}affiliate-record/admin/assign-post-referral`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_AFFILIATE_ORDER_LIST](context, payload) {
      // /api/v1/affiliate-record/admin/list
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}affiliate-record/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_AFFILIATE_ORDER_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [APPROVE_AFFILIATE_PAYOUT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}affiliate/admin/approve-affiliate-payout`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_AFFILIATE_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_AFFILIATE_EXISTING_MERCHANT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}affiliate/admin/create-affiliate-existing-merchant`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
