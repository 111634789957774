export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/announcements',
    name: 'announcements',
    redirect: 'announcements/list',
    component: () => import('@/views/administration/announcement/datatable.vue'),
    children: [
      {
        path: 'list',
        component: () => import('@/views/administration/announcement/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/helper',
    name: 'admin-helper-page',
    redirect: 'helper/payout',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'withdrawal-tester',
        name: 'admin-helper-payout-tester-page',
        component: () => import('@/views/administration/stabilityTester/PayoutTester.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'deposit-tester',
        name: 'admin-helper-deposit-tester-page',
        component: () => import('@/views/administration/stabilityTester/depositTester.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },

      {
        path: 'payout',
        name: 'admin-helper-payout-page',
        component: () => import('@/views/administration/helper/payout.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'bills',
        name: 'admin-helper-bills-page',
        component: () => import('@/views/administration/helper/bills.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/cost-centre',
    name: 'administration-cost-centre-txn',
    redirect: 'cost-centre/transaction/all',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'transaction/:type/:year?/:month?/:day?',
        name: 'administration-cost-centre-txn-list',
        component: () => import('@/views/administration/costCentre/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'payout/:type/:year?/:month?/:day?',
        name: 'administration-cost-centre-payout-list',
        component: () => import('@/views/administration/costCentre/payout.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/onboardingv2',
    name: 'administration-onboardingv2',
    redirect: 'onboardingv2/local',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'local',
        name: 'administration-onboardingv2-local',
        component: () => import('@/views/administration/onboardingv2/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'oversea',
        name: 'administration-onboardingv2-oversea',
        component: () => import('@/views/administration/onboardingv2/oversea.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'service-audit',
        name: 'administration-onboardingv2-service-audit',
        component: () => import('@/views/administration/onboardingv2/serviceAuditList.vue'),
        meta: {
          pageTitle: 'Service Audit',
          breadcrumb: [
            {
              text: 'Service Audit',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/users',
    name: 'administration-users',
    redirect: 'users/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-users-datatable',
        component: () => import('@/views/administration/users/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-users-new',
        component: () => import('@/views/administration/users/Form.vue'),
        meta: {
          pageTitle: 'Users Form',
          breadcrumb: [
            {
              text: 'Users Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-users-form',
        component: () => import('@/views/administration/users/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/accounts',
    name: 'administration-accounts',
    redirect: 'accounts/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-accounts-datatable',
        component: () => import('@/views/administration/accounts/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: ':id/account-details',
        name: 'administration-accounts-details-info',
        component: () => import('@/views/administration/accounts/details/accountDetails.vue'),
        meta: {
          pageTitle: 'Account Details',
          breadcrumb: [
            {
              text: 'Account Details',
              active: true,
            },
          ],
        },
      },
      {
        path: ':id/account-details/collections/:collectionId/:mode',
        name: 'administration-accounts-details-collection-info',
        component: () => import('@/views/administration/accounts/details/collections/collectionDetails.vue'),
        meta: {
          pageTitle: 'Collection Details',
          breadcrumb: [
            {
              text: 'Collection Details',
              active: true,
            },
          ],
        },
      },
      {
        path: ':id/account-details/collections/:collectionId/view/invoice/:invoiceId',
        name: 'administration-accounts-details-collection-bill-info',
        component: () => import('@/views/administration/accounts/details/collections/BillDetails.vue'),
        meta: {
          pageTitle: 'Invoice Details',
          breadcrumb: [
            {
              text: 'Invoice Details',
              active: true,
            },
          ],
        },
      },
      {
        path: ':id/account-details/reports/:type/:selectedDate',
        name: 'administration-accounts-details-reports-viewer',
        component: () => import('@/views/administration/accounts/details/reports/reportViewer.vue'),
        meta: {
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Account Detail Reports',
              active: true,
            },
          ],
        },
      },
      {
        path: ':id/account-details/settlements',
        name: 'administration-accounts-details-settlement',
        component: () => import('@/views/administration/accounts/details/settlementsRecords/settlementRecords.vue'),
        meta: {
          pageTitle: 'Settlements',
          breadcrumb: [
            {
              text: 'Account Settlements',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-accounts-new',
        component: () => import('@/views/administration/accounts/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-accounts-form',
        component: () => import('@/views/administration/accounts/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/banks',
    name: 'administration-banks',
    redirect: 'banks/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-banks-datatable',
        component: () => import('@/views/administration/banks/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-banks-new',
        component: () => import('@/views/administration/banks/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-banks-form',
        component: () => import('@/views/administration/banks/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'administration-onboarding',
    redirect: 'onboarding/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-onboarding-datatable',
        component: () => import('@/views/administration/onboarding/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        props: true,
        name: 'administration-onboarding-detail-view',
        component: () => import('@/views/administration/onboarding/onboardDetailView.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-onboarding-new',
        component: () => import('@/views/administration/onboarding/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-onboarding-form',
        component: () => import('@/views/administration/onboarding/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },

  {
    path: '/commission-referral',
    name: 'administration-commission-referral',
    redirect: 'commission-referral/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-commission-referral-datatable',
        component: () => import('@/views/administration/commissionReferrals/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'detail/:id',
        name: 'administration-commission-referral-details',
        component: () => import('@/views/administration/commissionReferrals/commissionReferralsDetails.vue'),
        meta: {
          pageTitle: 'Details',
          breadcrumb: [
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/plans',
    name: 'administration-plans',
    redirect: 'plans/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-plans-datatable',
        component: () => import('@/views/administration/plans/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-plans-new',
        component: () => import('@/views/administration/plans/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: 'edit/:id',
        name: 'administration-plans-form',
        component: () => import('@/views/administration/plans/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: 'view/:id',
        name: 'administration-plans-view-form',
        component: () => import('@/views/administration/plans/View.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/custom-plans',
    name: 'administration-custom-plans',
    redirect: 'custom-plans/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-custom-plans-datatable',
        component: () => import('@/views/administration/custom-plans/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/referral',
    name: 'administration-lxreferral',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'datatable',
        name: 'administration-lxReferral-datatable',
        component: () => import('@/views/administration/lxReferral/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'orders',
        name: 'administration-lxReferral-orders-datatable',
        component: () => import('@/views/administration/referralRecords/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'payout',
        name: 'administration-referral-payout',
        component: () => import('@/views/administration/referralPayout/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/prefund',
    name: 'administration-prefund',
    redirect: 'prefund/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-prefund-datatable',
        component: () => import('@/views/administration/prefund/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-prefund-new',
        component: () => import('@/views/administration/prefund/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-prefund-form',
        component: () => import('@/views/administration/prefund/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/self-settlement',
    name: 'administration-self-settlement',
    redirect: 'self-settlement/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-self-settlement-datatable',
        component: () => import('@/views/administration/selfSettlement/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/settlement',
    name: 'administration-settlement',
    redirect: 'settlement/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-settlement-datatable',
        component: () => import('@/views/administration/settlement/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: ':uuid',
        name: 'administration-settlement-datatable-details',
        component: () => import('@/views/administration/settlement/settlementDetails.vue'),
        meta: {
          pageTitle: 'Settlement Details',
          breadcrumb: [
            {
              text: 'Settlement Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'list2',
        name: 'administration-settlement2-datatable',
        component: () => import('@/views/administration/settlement/datatable-new.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
    ],
  },

  {
    path: '/commissions',
    name: 'administration-commissions',
    redirect: 'commissions/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-commissions-datatable',
        component: () => import('@/views/administration/commissions/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: ':uuid',
        name: 'administration-commissions-datatable-details',
        component: () => import('@/views/administration/commissions/commissionDetails.vue'),
        meta: {
          pageTitle: 'Commission Details',
          breadcrumb: [
            {
              text: 'Commission Details',
              active: true,
            },
          ],
        },
      },
    ],
  },

  {
    path: '/payout',
    name: 'administration-payout',
    redirect: 'payout/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-payout-datatable',
        component: () => import('@/views/administration/payout/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-payout-new',
        component: () => import('@/views/administration/payout/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-payout-form',
        component: () => import('@/views/administration/payout/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/admins',
    name: 'administration-admins',
    redirect: 'admins/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-admins-datatable',
        component: () => import('@/views/administration/admins/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-admins-new',
        component: () => import('@/views/administration/admins/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-admins-form',
        component: () => import('@/views/administration/admins/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/payments',
    name: 'administration-payments',
    redirect: 'payments/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-payments-datatable',
        component: () => import('@/views/administration/payments/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-payments-new',
        component: () => import('@/views/administration/payments/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-payments-form',
        component: () => import('@/views/administration/payments/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/customer-bills',
    name: 'administration-customer-bills',
    redirect: 'customer-bills/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-customer-bills-datatable',
        component: () => import('@/views/administration/customer-bills/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-customer-bills-new',
        component: () => import('@/views/administration/customer-bills/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-customer-bills-form',
        component: () => import('@/views/administration/customer-bills/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/feedbacks',
    name: 'administration-feedbacks',
    redirect: 'feedbacks/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-feedbacks-datatable',
        component: () => import('@/views/administration/feedbacks/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-feedbacks-new',
        component: () => import('@/views/administration/feedbacks/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-feedbacks-form',
        component: () => import('@/views/administration/feedbacks/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/domains',
    name: 'administration-domains',
    redirect: 'domains/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-domains-datatable',
        component: () => import('@/views/administration/domains/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-domains-new',
        component: () => import('@/views/administration/domains/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-domains-form',
        component: () => import('@/views/administration/domains/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/options',
    name: 'administration-options',
    redirect: 'options/view',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'view',
        name: 'administration-options-view',
        component: () => import('@/views/administration/options/View.vue'),
        meta: {
          pageTitle: 'View',
          breadcrumb: [
            {
              text: 'View',
              active: true,
            },
          ],
        },
      },
      {
        path: 'list',
        name: 'administration-options-datatable',
        component: () => import('@/views/administration/options/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-options-new',
        component: () => import('@/views/administration/options/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-options-form',
        component: () => import('@/views/administration/options/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/audits',
    name: 'administration-audits',
    redirect: 'audits/list',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'list',
        name: 'administration-audits-datatable',
        component: () => import('@/views/administration/audits/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'administration-audits-new',
        component: () => import('@/views/administration/audits/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'administration-audits-form',
        component: () => import('@/views/administration/audits/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/profile',
    name: 'administration-user-profile',
    redirect: 'profile/view',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'view',
        name: 'administration-user-profile-view',
        component: () => import('@/views/administration/userme/profile.vue'),
        meta: {
          pageTitle: 'Profile',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },

  {
    path: '/maintenance',
    name: 'administration-maintenance',
    redirect: 'maintenance/view',
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      {
        path: 'view',
        name: 'administration-maintenance-list',
        component: () => import('@/views/administration/maintenance/maintenance.vue'),
        meta: {
          pageTitle: 'Profile',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },

  // merchant

  // public
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password-public',
    component: () => import('@/views/forgotPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot-username',
    name: 'forgot-username-public',
    component: () => import('@/views/forgotUsername.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/temp-whitelabel-login',
    name: 'whitelabel-login',
    component: () => import('@/views/whitelabelLogin.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/system-info',
    name: 'systemInfo',
    component: () => import('@/views/development/config.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
