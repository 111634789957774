import Vue from 'vue'
import store from '@/store'

function humanise(str) {
  if (str !== null) {
    let i; const
      frags = str.split('_')
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags.join(' ')
  }

  return 'NA'
}

const SystemHelper = {
  init() {
    console.log('responseHelperLoaded')
  },
  async resultProcessor(res) {
    let { response_code } = res.data
    let { description } = res.data
    let fatal = false

    switch (res.data.response_code) {
      case 401:
        // eslint-disable-next-line camelcase
        response_code = res.data.response_code
        description = 'NOT AUTHENTICATED'
        fatal = true
        break
      case 7777:
        // eslint-disable-next-line camelcase
        response_code = res.data.response_code
        description = 'SESSION EXPIRED'
        fatal = true
        break
      default:
        const desc = `<h5>Description</h5><p>${humanise(res.data.description)}</p><h5>Breakdown</h5><p>${humanise(res.data.breakdown_errors)}</p>`
        // eslint-disable-next-line camelcase
        response_code = res.data.response_code
        description = desc
        fatal = false
    }

    return {
      response_code,
      description,
      fatal,
    }
  },
  async errorHandler(res) {
    let param
    let defaultParam = {
      icon: 'error',
      // title: 'Caught Error',
      confirmButtonText: 'Confirm',
      customClass: {
        container: 'confirmation-swal',
        // header: 'bg-danger',
        confirmButton: 'btn btn-lg btn-danger',
        input: 'form-control',
      },
    }

    await this.resultProcessor(res).then(data => {
      console.log(data)

      param = {
        title: 'Error',
        html: `<br>${data.description}<br><span class="small">Error code: ${data.response_code}</span><br><br>`,
      }

      defaultParam = {
        ...defaultParam,
        ...param,
      }

      Vue.swal(defaultParam).then(result => {
        console.log(result)
        if (result.isConfirmed) {
          if (data.fatal) {
            store.dispatch('auth/logout')
          } else {
            console.log('hi')
          }
        }
      })
    })
  },
  async handleCatch(res) {
    let param
    let defaultParam = {
      title: 'handlecatch Error',
      confirmButtonText: 'Confirm',
      customClass: {
        // container: 'confirmation-swal',
        // header: 'bg-danger',
        // confirmButton: 'btn btn-sm btn-danger ml-1',
        input: 'form-control',
      },
    }

    await this.resultProcessor(res).then(data => {
      console.log(data)

      param = {
        title: data.response_code,
        html: data.description,
      }

      defaultParam = {
        ...defaultParam,
        ...param,
      }

      Vue.swal(defaultParam).then(result => {
        console.log(result)
        if (result.isConfirmed) {
          if (data.fatal) {
            store.dispatch('auth/logout')
          } else {
            console.log('hi')
          }
        }
      })
    })
  },
  async successHandler(resultObj) {
    const suppress = true
    let param
    let defaultParam = {
      title: 'Success',
      confirmButtonText: 'Confirm',
      customClass: {
        container: 'confirmation-swal',
        header: 'bg-success',
        confirmButton: 'btn btn-sm btn-success ml-1',
        input: 'form-control',
      },
    }

    defaultParam = {
      ...defaultParam,
      ...param,
    }

    if (!suppress) {
      Vue.swal(defaultParam)
    }
  },
}

export default SystemHelper
