// action types
export const APPEND_BREADCRUMB = 'appendBreadcrumb'
export const RESET_BREADCRUMB = 'resetBreadcrumb'

// mutation types
export const SET_BREADCRUMB = 'setBreadcrumb'
export const ADD_BREADCRUMB = 'addBreadcrumb'

const getDefaultState = () => ({
  breadcrumbs: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    breadcrumbs(state) {
      return state.breadcrumbs
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    pageTitle(state) {
      const last = state.breadcrumbs[state.breadcrumbs.length - 1]
      if (last && last.title) {
        return last.title
      }
      return ''
    },
    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    altTitle(state) {
      const last = state.breadcrumbs[state.breadcrumbs.length - 1]
      if (last && last.altTitle) {
        return last.altTitle
      }
      return null
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    pageSubtitle(state) {
      const last = state.breadcrumbs[state.breadcrumbs.length - 1]
      if (last && last.subtitle) {
        return last.subtitle
      }
      return ''
    },
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [SET_BREADCRUMB](state, payload) {
      state.commit(SET_BREADCRUMB, payload)
    },

    /**
     * Add breadcrumb
     * @param state
     * @param payload
     */
    [ADD_BREADCRUMB](state, payload) {
      if (typeof payload === 'object') {
        payload.forEach(item => state.commit(APPEND_BREADCRUMB, item))
      } else {
        state.commit(APPEND_BREADCRUMB, payload)
      }
    },
    [RESET_BREADCRUMB](context) {
      context.commit(RESET_BREADCRUMB)
    },
  },
  mutations: {
    [APPEND_BREADCRUMB](state, payload) {
      state.breadcrumbs = [...state.breadcrumbs, payload]
    },
    [SET_BREADCRUMB](state, payload) {
      state.breadcrumbs = payload
    },
    [RESET_BREADCRUMB](state) {
      state.breadcrumbs = null
    },
  },
}
