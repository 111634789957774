import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

import { vm } from '@/main'

export const GET_ACCOUNTS = 'getAccounts'
export const GET_ACCOUNTS_ADMIN_LIST = 'getAccountsAdminList'
export const GET_ACCOUNT = 'getAccount'
export const CREATE_ACCOUNT = 'createAccount'
export const GET_ACCOUNTS_ME = 'getAccountMe'
export const UPDATE_ACCOUNT = 'updateAccount'
export const GET_ACCOUNT_USERS = 'getAccountUsers'
export const GET_ACCOUNT_USERS_ME = 'getAccountUsersMe'
export const ADD_USER_TO_ACCOUNT = 'addUserToAccount'
export const MERCHANT_SWITCH_ACCOUNT = 'merchantSwitchAccount'
export const GET_ACCOUNT_USERS_BY_ACCOUNT_ID = 'getAccountUsersByAccountId'

export const SET_ACCOUNTS_LIST = 'setAccountList'
export const SET_ACCOUNTS_ME = 'setAccountMe'
export const SET_ACCOUNT = 'setAccount'
export const SET_ACCOUNT_USERS_LIST = 'setAccountUserList'
export const SET_ACCOUNT_USERS_ME_LIST = 'setAccountUserList'
export const SET_ACCOUNTS_ADMIN_LIST = 'setAccountAdminList'
export const SET_ADMIN_ACCOUNT_TYPE = 'setAccountAccountType'
export const SET_ADMIN_ACCOUNT_USER_DETAIL = 'setAdminAccountUserDetail'

export const UPDATE_CURRENT_VIEW_ACCOUNT = 'updateCurrentViewAccount'
export const SET_CURRENT_VIEW_ACCOUNT = 'setCurrentViewAccount'

export const UPDATE_FAVOURITE_ACCOUNT = 'updateFavouriteAccount'
export const SET_FAVOURITE_ACCOUNT = 'setFavouriteAccount'

export default {
  namespaced: true,
  state: {
    accountList: null,
    account: null,
    accountMe: null,
    accountUserList: null,
    accountAdminList: null,
    accountAdminAccountUserDetail: null,
    accountSystemTypeId: null,
    currentViewAccount: null,
    favouriteAcc: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getAccountId() {
      return parseInt(useJwt.getAccountId(), 10)
    },
    getCurrentAccountObj() {
      return JSON.parse(useJwt.getCurrentAccountObj())
    },
    getCurrentAccountTypeId: state => {
      const id = state.accountSystemTypeId
      if (id) {
        return id
      }
      return null
    },
    getCurrentAccountMe: state => {
      const currentState = state.accountMe

      if (currentState) {
        const list = currentState.list.data
        return list
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.accountAdminList
      if (list) {
        return list.list
      }
      return []
    },
    activeAccountList: state => {
      const currentState = state.accountMe

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    getMainAccountDetails: state => {
      const currentState = state.accountMe

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    getAccountAdminAccountUserDetail: state => {
      const currentState = state.accountAdminAccountUserDetail

      if (currentState) {
        const list = currentState
        return list
      }
      return []
    },
    accountAdminList: state => {
      const currentState = state.accountAdminList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          // return list
          if (vm.IS_CRM_PORTAL) {
            return list.filter(el => el.is_active)
          }
          return list
        }
        return []
      }
      return []
    },
    getCurrentViewAccount: state => {
      const currentState = state.currentViewAccount

      if (currentState) {
        const list = currentState
        return list
      }
      return []
    },
    getFavouriteAccount: state => {
      const currentState = state.favouriteAcc

      if (Array.isArray(currentState)) {
        const list = currentState
        return list
      }
      return []
    },
  },
  mutations: {
    [SET_ADMIN_ACCOUNT_TYPE](state, val) {
      state.accountSystemTypeId = val
    },
    [SET_ACCOUNTS_LIST](state, val) {
      state.accountList = val
    },
    [SET_ACCOUNTS_ADMIN_LIST](state, val) {
      state.accountAdminList = val
    },
    [SET_ACCOUNT](state, val) {
      state.account = val
    },
    [SET_ACCOUNTS_ME](state, val) {
      state.accountMe = val
    },
    [SET_ACCOUNT_USERS_LIST](state, val) {
      state.accountUserList = val
    },
    [SET_ACCOUNT_USERS_ME_LIST](state, val) {
      state.accountUserMeList = val
    },
    [SET_ADMIN_ACCOUNT_USER_DETAIL](state, val) {
      state.accountAdminAccountUserDetail = val
    },
    [SET_CURRENT_VIEW_ACCOUNT](state, val) {
      state.currentViewAccount = val
    },
    [SET_FAVOURITE_ACCOUNT](state, val) {
      state.favouriteAcc = val
    },
  },
  actions: {
    [UPDATE_CURRENT_VIEW_ACCOUNT](context, payload) {
      context.commit(SET_CURRENT_VIEW_ACCOUNT, payload)
    },
    [UPDATE_FAVOURITE_ACCOUNT](context, payload) {
      console.log('payload', payload)
      context.commit(SET_FAVOURITE_ACCOUNT, payload)
    },
    [GET_ACCOUNTS_ADMIN_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log('adminlistoken', res.data.token)
              console.log('getaccount', res.data.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ACCOUNTS_ADMIN_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNTS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log('getaccount', res.data.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ACCOUNTS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNTS_ME](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/me`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_ACCOUNTS_ME, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_ACCOUNT](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/${accountId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNT](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/${accountId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNT_USERS](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/${accountId}/users`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_ACCOUNT_USERS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNT_USERS_ME](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/users/me`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_ACCOUNT_USERS_ME_LIST, res.data.data)
              context.commit(SET_ADMIN_ACCOUNT_TYPE, res.data.data[0].system_id)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNT_USERS_BY_ACCOUNT_ID](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/admin/merchant-account/${accountId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_ACCOUNT_USER_DETAIL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [ADD_USER_TO_ACCOUNT](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/${accountId}/users`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [MERCHANT_SWITCH_ACCOUNT](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/merchant/${accountId}/switch`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_ACCOUNT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
