import { DateTime } from 'luxon'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_MERCHANT_DASHBOARD = 'getMerchantDashboard'
export const SET_MERCHANT_DASHBOARD = 'getMerchantDashboard'

export const GET_DASHBOARD_GENERATE_REPORT_DAY = 'getDashboardGenerateReportDay'
export const SET_DASHBOARD_GENERATE_REPORT_DAY = 'setDashboardGenerateReportDay'

export const GET_DASHBOARD_GENERATE_REPORT_LAST_WEEK = 'getDashboardGenerateReportLastWeek'
export const SET_DASHBOARD_GENERATE_REPORT_LAST_WEEK = 'setDashboardGenerateReportLastWeek'

export const GET_DASHBOARD_GENERATE_REPORT_MONTH = 'getDashboardGenerateReportMonth'
export const SET_DASHBOARD_GENERATE_REPORT_MONTH = 'setDashboardGenerateReportMonth'

export const GET_DASHBOARD_GENERATE_REPORT_YEAR = 'getDashboardGenerateReportYear'
export const SET_DASHBOARD_GENERATE_REPORT_YEAR = 'setDashboardGenerateReportYear'

export const GET_ADMIN_DASHBOARD = 'getAdminDashboard'
export const SET_ADMIN_DASHBOARD = 'setAdminDashboard'

export const GET_ADMIN_CUSTOMER_DASHBOARD = 'getAdminCustomerDashboard'
export const SET_ADMIN_CUSTOMER_DASHBOARD = 'setAdminCustomerDashboard'

export const GET_ADMIN_CUSTOMER_DAILY_DASHBOARD = 'getAdminCustomerDailyDashboard'
export const SET_ADMIN_CUSTOMER_DAILY_DASHBOARD = 'setAdminCustomerDailyDashboard'

export const GET_CRM_REPORTING_PAYOUT = 'getCrmReportingPayout'
export const SET_CRM_REPORTING_PAYOUT = 'setCrmReportingPayout'

export const RECENT_CUSTOMER_BILLS_MINUTE = 'getRecentCustomerBillsMinute'
export const SET_RECENT_CUSTOMER_BILLS_MINUTE = 'setRecentCustomerBillsMinute'

export const RECENT_CUSTOMER_PAYOUT_MINUTE = 'getRecentCustomerPayoutMinute'
export const SET_RECENT_CUSTOMER_PAYOUT_MINUTE = 'setRecentCustomerPayoutMinute'

export const GET_CRM_REPORTING_BILL = 'getCrmReportingBill'
export const SET_CRM_REPORTING_BILL = 'setCrmReportingBill'

export const GET_CRM_REPORTING_PAYOUT_DASHBOARD = 'getCrmReportingPayoutDashboard'
export const SET_CRM_REPORTING_PAYOUT_DASHBOARD = 'setCrmReportingPayoutDashboard'

export default {
  namespaced: true,
  state: {
    merchantDashboard: {},
    merchantDashboardDay: {},
    merchantDashboardMonth: {},
    merchantDashboardYear: {},
    merchantDashboardLastWeek: {},
    adminDashboard: {},
    adminDashboardArea: {},
    adminDashboardBar: {},

    adminCustomerDashboard: {},
    adminCustomerDailyDashboard: {},

    crmBillReporting: {},
    crmPayoutReporting: {},

    crmPayoutDashboardReporting: {},

    recentBillsMinute: {},
    recentPayoutMinute: {},
  },
  getters: {
    dashboardData: state => {
      const currentState = state.merchantDashboard

      if (currentState) {
        return currentState
      }
      return []
    },
    adminDashboardData: state => {
      const currentState = state.adminDashboard

      if (currentState) {
        return currentState
      }
      return []
    },
    adminCustomerDashboardData: state => {
      const currentState = state.adminCustomerDashboard

      if (currentState) {
        return currentState
      }
      return []
    },
    adminCrmPayoutDashboardData: state => {
      const currentState = state.crmPayoutReporting

      if (currentState) {
        return currentState
      }
      return []
    },
    adminCrmBillDashboardData: state => {
      const currentState = state.crmBillReporting

      if (currentState) {
        return currentState
      }
      return []
    },

    adminCustomerDailyDashboardData: state => {
      const currentState = state.adminCustomerDailyDashboard

      if (currentState) {
        return currentState
      }
      return []
    },
    adminRecentBillsMinuteData: state => {
      const currentState = state.recentBillsMinute

      if (currentState) {
        return currentState
      }
      return []
    },
    adminRecentPayoutMinuteData: state => {
      const currentState = state.recentPayoutMinute

      if (currentState) {
        return currentState
      }
      return []
    },
    adminPayoutDashboardReporting: state => {
      const currentState = state.crmPayoutDashboardReporting

      if (currentState) {
        return currentState
      }
      return []
    },
    activeChartData: state => type => {
      let currentState
      if (type === 'day') {
        currentState = state.merchantDashboardDay
      }
      if (type === 'month') {
        currentState = state.merchantDashboardMonth
      }
      if (type === 'year') {
        currentState = state.merchantDashboardYear
      }
      if (type === 'week') {
        currentState = state.merchantDashboardLastWeek
      }

      if (currentState) {
        return currentState
      }
      return []
    },
    adminDasboardChart: state => type => {
      let currentState
      if (type === 'area') {
        currentState = state.adminDashboardArea
      }
      if (type === 'bar') {
        currentState = state.adminDashboardBar
      }

      if (currentState) {
        return currentState
      }
      return []
    },
  },
  mutations: {
    [SET_DASHBOARD_GENERATE_REPORT_DAY](state, val) {
      state.merchantDashboardDay = val
    },
    [SET_DASHBOARD_GENERATE_REPORT_LAST_WEEK](state, val) {
      state.merchantDashboardLastWeek = val
    },
    [SET_DASHBOARD_GENERATE_REPORT_MONTH](state, val) {
      state.merchantDashboardMonth = val
    },
    [SET_DASHBOARD_GENERATE_REPORT_YEAR](state, val) {
      state.merchantDashboardYear = val
    },
    [SET_MERCHANT_DASHBOARD](state, val) {
      state.merchantDashboard = val
    },
    [SET_ADMIN_DASHBOARD](state, val) {
      state.adminDashboard = val
    },
    [SET_ADMIN_CUSTOMER_DASHBOARD](state, val) {
      state.adminCustomerDashboard = val
    },
    [SET_ADMIN_CUSTOMER_DAILY_DASHBOARD](state, val) {
      state.adminCustomerDailyDashboard = val
    },
    [SET_CRM_REPORTING_PAYOUT](state, val) {
      state.crmPayoutReporting = val
    },
    [SET_CRM_REPORTING_BILL](state, val) {
      state.crmBillReporting = val
    },
    [SET_CRM_REPORTING_PAYOUT_DASHBOARD](state, val) {
      state.crmPayoutDashboardReporting = val
    },
    [SET_RECENT_CUSTOMER_BILLS_MINUTE](state, val) {
      state.recentBillsMinute = val
    },
    [SET_RECENT_CUSTOMER_PAYOUT_MINUTE](state, val) {
      state.recentPayoutMinute = val
    },
  },
  actions: {
    [GET_MERCHANT_DASHBOARD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/admin/merchant-dashboard/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_DASHBOARD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_DASHBOARD_GENERATE_REPORT_DAY](context, payload) {
      if (!payload.date) {
        payload.date = DateTime.now().toFormat('dd-MM-yyyy')
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/admin-${payload.accountId}/dashboard-generate-report/day-${payload.date}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_DASHBOARD_GENERATE_REPORT_DAY, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_DASHBOARD_GENERATE_REPORT_LAST_WEEK](context, payload) {
      // alert(payload.accountId)
      if (!payload.date) {
        payload.date = DateTime.now().toFormat('dd-MM-yyyy')
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/admin-${payload.accountId}/dashboard-generate-report/last-week-${payload.date}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_DASHBOARD_GENERATE_REPORT_LAST_WEEK, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_DASHBOARD_GENERATE_REPORT_MONTH](context, payload) {
      if (!payload.year) {
        payload.year = DateTime.now().year
      }
      if (!payload.month) {
        payload.month = DateTime.now().month
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/admin-${payload.accountId}/dashboard-generate-report/month-${payload.month}?skip=${payload.skip}&limit=${payload.limit}&year=${payload.year}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_DASHBOARD_GENERATE_REPORT_MONTH, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_DASHBOARD_GENERATE_REPORT_YEAR](context, payload) {
      if (!payload.year) {
        payload.year = DateTime.now().year
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/admin-${payload.accountId}/dashboard-generate-report/${payload.year}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_DASHBOARD_GENERATE_REPORT_YEAR, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ADMIN_DASHBOARD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/admin-dashboard`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_DASHBOARD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ADMIN_CUSTOMER_DASHBOARD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/admin/payout-merchant-dashboard/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_CUSTOMER_DASHBOARD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ADMIN_CUSTOMER_DAILY_DASHBOARD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/admin/daily-merchant-dashboard/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_CUSTOMER_DAILY_DASHBOARD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_CRM_REPORTING_BILL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}crm-reporting/admin/customer-bill-status`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CRM_REPORTING_BILL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_CRM_REPORTING_PAYOUT_DASHBOARD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}crm-reporting/admin/check-balance-settlement`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CRM_REPORTING_PAYOUT_DASHBOARD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_CRM_REPORTING_PAYOUT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}crm-reporting/admin/payout-status`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CRM_REPORTING_PAYOUT, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [RECENT_CUSTOMER_BILLS_MINUTE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}crm-reporting/admin/recent-customer-bill-minute`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_RECENT_CUSTOMER_BILLS_MINUTE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [RECENT_CUSTOMER_PAYOUT_MINUTE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}crm-reporting/admin/recent-payout-minute`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_RECENT_CUSTOMER_PAYOUT_MINUTE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
