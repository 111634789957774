import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'
import { SET_SETTLEMENT_OVERVIEW } from '@/store/administration/settlement'

export const GET_COMMISSIONS_SETTLEMENT_LIST = 'getCommissionSettlementList'
export const SET_COMMISSIONS_SETTLEMENT_LIST = 'setCommissionSettlementList'

export const GET_COMMISSIONS_DETAIL = 'getCommissionDetail'
export const SET_COMMISSIONS_DETAIL = 'setCommissionDetail'

export const GET_COMMISSIONS_RECORDS = 'getCommissionRecordItems'
export const SET_COMMISSIONS_RECORDS = 'SetCommissionRecordItems'

export const GET_COMMISSIONS_RECORDS_LIST = 'getCommissionRecordList'
export const SET_COMMISSIONS_RECORDS_LIST = 'setCommissionRecordList'

export const GET_COMMISSION_OVERVIEW = 'getCommissionOverview'
export const SET_COMMISSION_OVERVIEW = 'setCommissionOverview'

export const GET_COMMISSION_REFERRAL_LIST = 'getCommissionReferralList'
export const SET_COMMISSION_REFERRAL_LIST = 'setCommissionReferralList'

export const GET_COMMISSION_REFERRALS_REFERREE = 'getCommissionReferralReferreeList'
export const SET_COMMISSION_REFERRALS_REFERREE = 'setCommissionReferralReferreeList'

export const CREATE_COMMISSION_PAYOUT = 'createCommissionPayout'
export const ASSIGN_REFERRAL_TO_COMMISSION = 'assignReferralToCommission'

export default {
  namespaced: true,
  state: {
    commissionSettlementList: null,
    commissionRecordsList: null,
    commissionDetail: null,
    commissionsOverview: null,
    commissionReferralList: null,
    commissionReferralReferreeList: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getCommissionSettlementList: state => {
      const currentState = state.commissionSettlementList
      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getCommissionReferralList: state => {
      const currentState = state.commissionReferralList
      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getCommissionReferralReferreeList: state => {
      const currentState = state.commissionReferralReferreeList
      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getCommissionOverview: state => {
      const currentState = state.commissionsOverview
      if (currentState) {
        return currentState
      }
      return []
    },
    getCommissionRecordList: state => {
      const currentState = state.commissionRecordsList
      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.commissionSettlementList
      if (list) {
        return list
      }
      return []
    },
  },
  mutations: {
    [SET_COMMISSIONS_SETTLEMENT_LIST](state, val) {
      state.commissionSettlementList = val
    },
    [SET_COMMISSIONS_DETAIL](state, val) {
      state.commissionDetail = val
    },
    [SET_COMMISSION_OVERVIEW](state, val) {
      state.commissionsOverview = val
    },
    [SET_COMMISSIONS_RECORDS_LIST](state, val) {
      state.commissionRecordsList = val
    },
    [SET_COMMISSION_REFERRAL_LIST](state, val) {
      state.commissionReferralList = val
    },
    [SET_COMMISSION_REFERRALS_REFERREE](state, val) {
      state.commissionReferralReferreeList = val
    },
    [SET_COMMISSION_OVERVIEW](state, val) {
      state.commissionsOverview = val
    },
  },
  actions: {
    [GET_COMMISSIONS_SETTLEMENT_LIST](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}commission-settlements/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMMISSIONS_SETTLEMENT_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_COMMISSIONS_DETAIL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}commission-settlements/admin/commission-settlement-uuid`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMMISSIONS_DETAIL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_COMMISSION_OVERVIEW](context) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}commission-settlements/admin/overview-statistic`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMMISSION_OVERVIEW, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_COMMISSION_REFERRALS_REFERREE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}commission-settings/admin/get-commission-details`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMMISSION_REFERRALS_REFERREE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_COMMISSION_REFERRAL_LIST](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}commission-settings/admin/commission-referral-list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMMISSION_REFERRAL_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_COMMISSION_PAYOUT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/create-commission-payout`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [ASSIGN_REFERRAL_TO_COMMISSION](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}commission-settings/admin/assign-referral-to-commission`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_COMMISSIONS_RECORDS_LIST](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}commission-records/admin/commission-uuid-list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMMISSIONS_RECORDS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_COMMISSIONS_RECORDS](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}commission-settlements/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMMISSIONS_RECORDS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

  },
}
