import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_PORTAL_ONBOARDING_LIST = 'getPortalOnboardingList'
export const APPROVE_PORTAL_ONBOARDING_USER = 'approvePortalOnboardingUser'
export const REJECT_PORTAL_ONBOARDING_USER = 'rejectPortalOnboardingUser'
export const DOWNLOAD_ONBOARDING_FILE = 'downloadOnboardingFile'

export const SELECTED_ONBOARDING_USER_DETAIL = 'selectedOnboardingUserDetail'
export const SET_PORTAL_ONBOARDING_LIST = 'setPortalOnboardingList'
export const SET_SELECTED_PORTAL_ONBOARDING_USER_DETAIL = 'setPortalOnboardingUserDetail'
export const UPDATE_SELECTED_PORTAL_ONBOARDING_USER_DETAIL = 'updateSelectedPortalOnboardingUserDetail'

export default {
  namespaced: true,
  state: {
    onboardingList: null,
    onboardingSelectedUserDetail: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfo: state => {
      const list = state.onboardingList
      if (list) {
        return list
      }
      return []
    },
    getPortalOnboardingList: state => {
      const currentState = state.onboardingList

      if (currentState) {
        const list = currentState
        return list.data.list
      }
      return []
    },
    getPortalOnboardingSelectedDetail: state => {
      const currentState = state.onboardingSelectedUserDetail

      if (currentState) {
        const list = currentState
        return list
      }
      return []
    },
    getPortalOnboardingSelectedDetailByKey: state => id => {
      const currentState = state.onboardingList

      if (currentState) {
        const list = currentState.filter(item => item.id === id)
        return list
      }
      return []
    },
  },
  mutations: {
    [SET_PORTAL_ONBOARDING_LIST](state, val) {
      state.onboardingList = val
    },
    [SET_SELECTED_PORTAL_ONBOARDING_USER_DETAIL](state, val) {
      state.onboardingSelectedUserDetail = val
    },
  },
  actions: {
    [UPDATE_SELECTED_PORTAL_ONBOARDING_USER_DETAIL](context, payload) {
      context.commit(SET_SELECTED_PORTAL_ONBOARDING_USER_DETAIL, payload)
    },
    [GET_PORTAL_ONBOARDING_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}portal/portal-form-list`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log('res.data.data portal onboard list', res.data.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_PORTAL_ONBOARDING_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [APPROVE_PORTAL_ONBOARDING_USER](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}portal/portal-approve-form`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch(err)
            reject(err)
          })
      })
    },
    [REJECT_PORTAL_ONBOARDING_USER](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}portal/portal-reject-form`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch(err)
            reject(err)
          })
      })
    },
    [DOWNLOAD_ONBOARDING_FILE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}portal/portal-download-file`, payload, {
          responseType: 'blob',
        })
          .then(res => {
            console.log(res.data)
            const blob = res.data
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onload = e => {
              const a = document.createElement('a')
              a.download = payload.file_name
              a.href = e.target.result
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
            }
          })
      })
    },
  },

}
