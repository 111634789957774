import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_ADMIN_PAYOUT_RECORDS = 'getAdminPayoutRecords'
export const GET_ADMIN_PAYOUT_RECORDS_FILTERED = 'getAdminPayoutRecordsFiltered'
export const UPDATE_ADMIN_PAYOUT_RECORDS = 'updateAdminPayoutRecords'
export const SET_ADMIN_PAYOUT_RECORDS_FILTERED = 'setAdminPayoutRecordsFiltered'
export const SET_ADMIN_PAYOUT_RECORDS = 'setAdminPayoutRecords'
export const CREATE_PAYOUT_RECORD_ADMIN = 'createPayoutRecordAdmin'

export default {
  namespaced: true,
  state: {
    payoutRecordList: null,
    payoutRecordFilteredList: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfo: state => {
      const list = state.payoutRecordList
      if (list) {
        return list.list
      }
      return []
    },
    activePayoutRecordList: state => {
      const currentState = state.payoutRecordList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    getPayoutRecordList: state => {
      const currentState = state.payoutRecordList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getPayoutRecordFilteredList: state => {
      const currentState = state.payoutRecordFilteredList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_ADMIN_PAYOUT_RECORDS](state, val) {
      state.payoutRecordList = val
    },
    [SET_ADMIN_PAYOUT_RECORDS_FILTERED](state, val) {
      state.payoutRecordFilteredList = val
    },
  },
  actions: {
    [GET_ADMIN_PAYOUT_RECORDS](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payout-records/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_PAYOUT_RECORDS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ADMIN_PAYOUT_RECORDS_FILTERED](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      if (!payload.record_status) {
        // eslint-disable-next-line no-param-reassign
        payload.record_status = 1
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payout-records/admin/filter-status-list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_PAYOUT_RECORDS_FILTERED, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_ADMIN_PAYOUT_RECORDS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payout-records/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_PAYOUT_RECORD_ADMIN](context, payload) {
      return new Promise((resolve, reject) => {
        // https://apildap.leanx.io/api/v1/payment-services/switch/create-payout-invoice
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/create-payout-invoice`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
