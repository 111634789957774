import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_CUSTOMER_BILLS_LIST = 'getCustomerBillsList'
export const FAILED_BILL_ADJUSTMENT = 'failedBillAdjustment'
export const FPX_FAILED_REASON = 'fpxFailedReason'
export const SET_CUSTOMER_BILLS_LIST = 'setCustomerBillsList'

export default {
  namespaced: true,
  state: {
    customerBillsList: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfoByStateKey: state => stateKey => {
      console.log('here', state[stateKey])
      console.log('here', state)
      console.log('here', stateKey)

      const list = state[stateKey]
      if (list) {
        return list.list
      }
      return []
    },
    customerBillsList: state => {
      const currentState = state.customerBillsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.customerBillsList
      if (list) {
        return list
      }
      return []
    },
  },
  mutations: {
    [SET_CUSTOMER_BILLS_LIST](state, val) {
      state.customerBillsList = val
    },
  },
  actions: {
    [GET_CUSTOMER_BILLS_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CUSTOMER_BILLS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [FAILED_BILL_ADJUSTMENT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/failed-adjustment/${payload}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [FPX_FAILED_REASON](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/fpx-failed-reason/${payload.transaction_invoice_no}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
