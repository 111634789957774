import _ from 'lodash'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_RECENT_MASTER_POOL_BALANCE_ADMIN = 'getRecentMasterPoolBalanceAdmin'
export const SET_RECENT_MASTER_POOL_BALANCE_ADMIN = 'setRecentMasterPoolBalanceAdmin'

export const GET_MASTER_POOL_LATEST_BALANCE_ADMIN = 'getMasterPoolLatestBalanceAdmin'
export const SET_MASTER_POOL_LATEST_BALANCE_ADMIN = 'setMasterPoolLatestBalanceAdmin'

export default {
  namespaced: true,
  state: {
    masterPoolBalanceAdmin: null,
    masterPoolLatestBalanceAdmin: null,
  },
  getters: {
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfo: state => {
      const list = state.masterPoolBalanceAdmin
      if (list) {
        return list.list
      }
      return []
    },
    getMasterPoolBalanceList: state => {
      const currentState = state.masterPoolBalanceAdmin

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getMasterPoolLatestBalance: state => {
      const currentState = state.masterPoolLatestBalanceAdmin

      if (currentState) {
        return currentState
      }
      return []
    },
  },
  mutations: {
    [SET_RECENT_MASTER_POOL_BALANCE_ADMIN](state, val) {
      state.masterPoolBalanceAdmin = val
    },
    [SET_MASTER_POOL_LATEST_BALANCE_ADMIN](state, val) {
      state.masterPoolLatestBalanceAdmin = val
    },
  },
  actions: {
    [GET_RECENT_MASTER_POOL_BALANCE_ADMIN](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}master-pool-balance/admin/merchant-recent-master-pool-balances/${payload.id}?skip=${payload.skip}&limit=${payload.limit}`, payload)

          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              context.commit(SET_RECENT_MASTER_POOL_BALANCE_ADMIN, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MASTER_POOL_LATEST_BALANCE_ADMIN](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting master-pool-balance/admin/latest-balance/693
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}master-pool-balance/admin/latest-balance/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              context.commit(SET_MASTER_POOL_LATEST_BALANCE_ADMIN, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
