import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import SystemHelper from '@/common/systemHelper'

import systemConstants from '@/store/administration/systemConstants'

import STATES from '@/assets/static-data/states.json'
import CITIES from '@/assets/static-data/cities.json'
import { SET_BREADCRUMB } from '@/store/app/breadcrumbs'

export const GET_SYSTEM_CONSTANTS = 'getSystemConstants'
export const GET_ONBOARD_SYSTEM_CONSTANTS = 'getOnboardSystemConstants'
export const SET_SYSTEM_CONSTANTS = 'setSystemConstantsList'
export const SET_ONBOARD_SYSTEM_CONSTANTS = 'setOnboardSystemConstantsList'
export const TOGGLE_CHECK_BANK_ACCOUNT_MODAL = 'toggleCheckBankAccountModal'
export const SET_TOGGLE_CHECK_BANK_ACCOUNT_MODAL = 'setToggleCheckBankAccountModal'

export const UPDATE_CHECK_BANK_ACCOUNT_PAYLOAD = 'updateCheckBankAccountModal'
export const SET_CHECK_BANK_ACCOUNT_PAYLOAD = 'setCheckBankAccountModal'

export default {
  namespaced: true,
  state: {
    systemConstantsList: systemConstants,
    states: STATES,
    cities: CITIES,
    onboardConstants: {},
    showBankAccountModal: false,
    checkBankAccountPayload: null,
  },
  getters: {
    getCheckBankAccountModalStatus(state) {
      return state.showBankAccountModal
    },
    getBankList: state => {
      const currentState = state.systemConstantsList
      if (currentState && currentState.banks) {
        return currentState.banks
      } return []
    },
    getBankName(id) {
      let numId = id
      if (typeof id === 'string') {
        numId = parseInt(id, 10)
      }
      const list = this.$store.getters['systemConstants/getBankList']
      if (Array.isArray(list) && list.length > 0) {
        const selected = list.filter(el => el.id === numId)
        console.log(selected)
        if (Array.isArray(selected) && selected.length > 0) {
          return selected[0].name
        } return id
      } return id
    },
    getCityByStateId: state => key => {
      const list = state.cities
      if (Array.isArray(list) && list.length) {
        return list.filter(el => el.isInId === key && el.name !== null)
      } return state.cities
    },
    getStates: state => {
      // test
      console.log(state)
      return state.states
    },
    getStatesByKey: state => key => {
      const list = state.states
      if (Array.isArray(list) && list.length) {
        const found = list.filter(el => el.id === key && el.name !== null)
        return found[0].name
      } return state.states
    },
    getSystemConstantsByKey: state => key => {
      const obj = state.systemConstantsList[key]
      if (obj) {
        return obj
      }
      return []
    },
    getOnboardSystemConstantsByKey: state => key => {
      const obj = state.onboardConstants[key]
      if (obj) {
        return obj
      }
      return []
    },
    activeSystemConstantsList: state => {
      const currentState = state.systemConstantsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    systemConstantsList: state => {
      const currentState = state.systemConstantsList

      console.log('currentState-->', currentState)
      if (currentState) {
        return currentState
      }
      return []
    },
  },
  mutations: {
    [SET_SYSTEM_CONSTANTS](state, val) {
      state.systemConstantsList = val
    },
    [SET_TOGGLE_CHECK_BANK_ACCOUNT_MODAL](state, val) {
      state.showBankAccountModal = val
    },
    [SET_CHECK_BANK_ACCOUNT_PAYLOAD](state, val) {
      state.checkBankAccountPayload = val
    },
    [SET_ONBOARD_SYSTEM_CONSTANTS](state, val) {
      state.onboardConstants = val
    },
  },
  actions: {
    [TOGGLE_CHECK_BANK_ACCOUNT_MODAL](state, payload) {
      state.commit(SET_TOGGLE_CHECK_BANK_ACCOUNT_MODAL, payload)
    },
    [UPDATE_CHECK_BANK_ACCOUNT_PAYLOAD](state, payload) {
      state.commit(SET_CHECK_BANK_ACCOUNT_PAYLOAD, payload)
    },
    [GET_SYSTEM_CONSTANTS](context) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}onboard/constant/system-constant`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              context.commit(SET_SYSTEM_CONSTANTS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ONBOARD_SYSTEM_CONSTANTS](context) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}portal/portal-constant`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              context.commit(SET_ONBOARD_SYSTEM_CONSTANTS, res.data.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
