import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_ADMIN_SETTLEMENT_RECORDS = 'getAdminSettlementRecords'
export const GET_ADMIN_PAGINATION_SETTLEMENT_RECORDS = 'getAdminPaginationSettlementRecords'
export const GET_SETTLEMENT_OVERVIEW = 'getSettlementOverview'
export const SET_SETTLEMENT_OVERVIEW = 'setSettlementOverview'
export const UPDATE_ADMIN_SETTLEMENT_RECORDS = 'updateAdminSettlementRecords'
export const CREATE_SETTLEMENT_PAYOUT_INVOICE = 'createSettlementPayoutInvoice'
export const CREATE_SETTLEMENT_PAYOUT_CHANNEL = 'createSettlementPayoutChannel'
export const UPLOAD_ADMIN_SETTLEMENT = 'uploadAdminSettlement'
export const SET_ADMIN_SETTLEMENT_RECORDS = 'setAdminSettlementRecords'
export const GET_SETTLEMENT_ADJUSTMENT_RECORDS = 'getSettlementAdjustmentRecords'
export const SET_SETTLEMENT_ADJUSTMENT_RECORDS = 'setSettlementAdjustmentRecords'
export const SETTLEMENT_CHECK_INVOICE_NO = 'settlementCheckInvoiceNo'
export const ADD_SETTLEMENT_ADJUSTMENT = 'addSettlementAdjustment'

export const GET_SETTLEMENT_RECORD_ITEMS = 'getSettlementRecordItems'
export const GET_SETTLEMENT_DETAIL = 'getSettlementDetail'
export const SET_SETTLEMENT_DETAIL = 'setSettlementDetail'
export const SET_SETTLEMENT_RECORD_ITEMS = 'setSettlementRecordItems'
export const TRANSACTION_SETTLEMENT_ITEM_RECONCILE = 'doSettlementItemReconcile'
export const BULK_RECONCILE = 'bulkReconcile'

export const GET_ADMIN_SETTLEMENT_REPORT_TXN = 'getAdminSettlementReportTxn'
export const SET_ADMIN_SETTLEMENT_REPORT_TXN = 'setAdminSettlementReportTxn'

export const GET_ADMIN_SETTLEMENT_REPORT_PAYOUT = 'getAdminSettlementReportPayout'
export const SET_ADMIN_SETTLEMENT_REPORT_PAYOUT = 'setAdminSettlementReportPayout'

export default {
  namespaced: true,
  state: {
    settlementList: null,
    settlementDetail: null,
    settlementOverview: null,
    settlementAdjustmentList: null,
    settlementRecordItems: null,

    settlementReportTxnList: null,
    settlementReportPayoutList: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfo: state => {
      const list = state.settlementList
      if (list) {
        return list.list
      }
      return []
    },
    getSettlementOverview: state => {
      const list = state.settlementOverview
      if (list) {
        return list
      }
      return []
    },
    activeSettlementRecordList: state => {
      const currentState = state.settlementList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    getSettlementRecordList: state => {
      const currentState = state.settlementList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getSettlementRecordItems: state => {
      const currentState = state.settlementRecordItems

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getSettlementAdjustmentList: state => {
      const currentState = state.settlementAdjustmentList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getSettlementReportTxnList: state => {
      const currentState = state.settlementReportTxnList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getSettlementReportPayoutList: state => {
      const currentState = state.settlementReportPayoutList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_ADMIN_SETTLEMENT_RECORDS](state, val) {
      state.settlementList = val
    },
    [SET_SETTLEMENT_OVERVIEW](state, val) {
      state.settlementOverview = val
    },
    [SET_SETTLEMENT_ADJUSTMENT_RECORDS](state, val) {
      state.settlementAdjustmentList = val
    },
    [SET_SETTLEMENT_RECORD_ITEMS](state, val) {
      state.settlementRecordItems = val
    },
    [SET_ADMIN_SETTLEMENT_REPORT_TXN](state, val) {
      state.settlementReportTxnList = val
    },
    [SET_ADMIN_SETTLEMENT_REPORT_PAYOUT](state, val) {
      state.settlementReportPayoutList = val
    },
    [SET_SETTLEMENT_DETAIL](state, val) {
      state.settlementDetail = val
    },
  },
  actions: {
    [UPLOAD_ADMIN_SETTLEMENT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-records/upload/settlement-daily`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_SETTLEMENT_PAYOUT_INVOICE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/create-settlement-payout-invoice`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_SETTLEMENT_PAYOUT_CHANNEL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/create-settlement-payout-channel`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ADMIN_SETTLEMENT_RECORDS](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-records/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_SETTLEMENT_RECORDS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ADMIN_PAGINATION_SETTLEMENT_RECORDS](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        // GET_ADMIN_PAGINATION_SETTLEMENT_RECORDS /api/v1/settlement-records/admin-pagination/list
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-records/admin-pagination/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_SETTLEMENT_RECORDS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SETTLEMENT_OVERVIEW](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-records/settlement-overall`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SETTLEMENT_OVERVIEW, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SETTLEMENT_ADJUSTMENT_RECORDS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-adjustment-records/admin/list`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SETTLEMENT_ADJUSTMENT_RECORDS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SETTLEMENT_RECORD_ITEMS](context, payload) {
      return new Promise((resolve, reject) => {
        // api/v1/settlement-record-items/admin/list?skip=0&limit=100
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-record-items/admin/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SETTLEMENT_RECORD_ITEMS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SETTLEMENT_DETAIL](context, payload) {
      return new Promise((resolve, reject) => {
        // api/v1/settlement-record-items/admin/list?skip=0&limit=100
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-records/admin/settlement-uuid`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SETTLEMENT_DETAIL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [SETTLEMENT_CHECK_INVOICE_NO](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-adjustment-records/admin/check-invoice-no`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [ADD_SETTLEMENT_ADJUSTMENT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-adjustment-records/admin/add-settlement-adjustment`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_ADMIN_SETTLEMENT_RECORDS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payout-records/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [TRANSACTION_SETTLEMENT_ITEM_RECONCILE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-reconcile/admin-recon/settlement-transaction?settlement_id=${payload.settlement_uuid}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [BULK_RECONCILE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-reconcile/admin/bulk-reconcile-based-invoice`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_ADMIN_SETTLEMENT_REPORT_TXN](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        // https://stag-api.leanpay.my/api/v1/settlement-report/admin/transaction-list?skip=0&limit=100
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-report/admin/transaction-list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_SETTLEMENT_REPORT_TXN, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ADMIN_SETTLEMENT_REPORT_PAYOUT](context, payload) {
      if (!payload.skip) {
        // eslint-disable-next-line no-param-reassign
        payload.skip = 0
      }
      if (!payload.limit) {
        // eslint-disable-next-line no-param-reassign
        payload.limit = 10
      }
      return new Promise((resolve, reject) => {
        // https://stag-api.leanpay.my/api/v1/settlement-report/admin/payout-list?skip=0&limit=100
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-report/admin/payout-list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_SETTLEMENT_REPORT_PAYOUT, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
