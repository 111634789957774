import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import VuexPersist from 'vuex-persist'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import breadcrumbs from '@/store/app/breadcrumbs'

import systemConstants from '@/store/administration/systemConstants'
import general from '@/store/administration/general'

import administration from './administration'
import users from './administration/users'
import accounts from '@/store/administration/accounts'
import banks from '@/store/administration/banks'
import domains from '@/store/administration/domains'
import dashboard from '@/store/administration/dashboard'
import auditRequest from '@/store/administration/auditRequest'
import affiliate from '@/store/administration/affiliate'
import paymentRecords from '@/store/administration/paymentRecords'
import prefund from '@/store/administration/prefund'
import payout from '@/store/administration/payout'
import settlement from '@/store/administration/settlement'
import selfSettlement from '@/store/administration/selfSettlement'

import paymentUploadFile from '@/store/administration/paymentUploadFile'
import subscriptionsPlan from '@/store/administration/subscriptions'
import paymentRateExclusion from '@/store/administration/paymentRateExclusion'
import onboarding from '@/store/administration/onboarding'
import onboardingv2 from '@/store/administration/onboardingv2'
import commissions from '@/store/administration/commissions'
import collections from '@/store/administration/collections'
import systemOption from '@/store/administration/systemOptions'
import virtualAccounts from '@/store/administration/virtual-account'
import collectionPool from '@/store/administration/pools'

import customerBills from '@/store/administration/customerBills'
import referral from '@/store/administration/referral'
import auth from './auth'

// import collections from '@/store/administration/collections'
// import catalogs from '@/store/administration/catalogs'
// import businessOwnerDetails from '@/store/administration/businessOwnerDetails'
// import merchantAPI from '@/store/administration/merchantAPI'
// import customers from '@/store/administration/customers'
// import discounts from '@/store/administration/discounts'
// import pages from '@/store/administration/pages'
// import products from '@/store/administration/products'
// import recurring from '@/store/administration/recurring'
// import customerBills from '@/store/administration/customerBills'

// import systemConstants from '@/store/merchant/systemConstants'
// import merchant from './merchant'
// import auth from './auth'
// import users from './merchant/users'
// import accounts from '@/store/merchant/accounts'
// import banks from '@/store/merchant/banks'
// import collections from '@/store/merchant/collections'
// import businessOwnerDetails from '@/store/merchant/businessOwnerDetails'
// import merchantAPI from '@/store/merchant/merchantAPI'
// import customers from '@/store/merchant/customers'
// import discounts from '@/store/merchant/discounts'
// import products from '@/store/merchant/products'
// import productCategories from '@/store/merchant/productCategories'
// import customerBills from '@/store/merchant/customerBills'
// import dashboard from '@/store/merchant/dashboard'
// import paymentRecords from '@/store/merchant/paymentRecords'
// import subscriptions from '@/store/merchant/subscriptions'

// import paymentpage from '@/store/paymentpage/index'
// import invoicepage from '@/store/invoicepage/index'
// import paymentUploadFile from '@/store/merchant/paymentUploadFile'
// import successField from '@/store/merchant/successField'
// import supportDetails from '@/store/merchant/supportDetails'
// import shareSettings from '@/store/merchant/shareSettings'
// import paymentSettings from '@/store/merchant/paymentSettings'
import mediaStore from '@/store/administration/media'
import adminCustomerBills from '@/store/administration/adminCustomerBills'
import reportDownloads from '@/store/administration/reportDownloads'
import masterPool from '@/store/administration/masterPool'
import maintenance from '@/store/administration/maintenance'

Vue.use(Vuex)

// Vuex Persist
const vueLocalStorage = new VuexPersist({
  key: 'ADMIN_STORAGE_KEY',
  storage: window.localStorage,
  reducer: state => ({
    accounts: {
      favouriteAcc: state.accounts.favouriteAcc,
    },
  }),
})

export default new Vuex.Store({
  plugins: [vueLocalStorage.plugin],
  modules: {
    breadcrumbs,
    general,
    dashboard,
    app,
    appConfig,
    verticalMenu,
    systemConstants,
    auth,
    paymentRecords,
    auditRequest,
    subscriptionsPlan,
    onboarding,
    onboardingv2,
    masterPool,
    reportDownloads,
    commissions,
    collections,
    systemOption,
    prefund,
    payout,
    selfSettlement,
    settlement,
    virtualAccounts,
    paymentRateExclusion,
    collectionPool,
    customerBills,
    paymentUploadFile,
    referral,
    adminCustomerBills,
    maintenance,

    affiliate,
    administration,
    mediaStore,
    users,
    domains,
    accounts,
    banks,
  },
  strict: process.env.DEV,
})
