import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const SELECT_IMAGE_FOR_UPLOAD = 'selectImageForUpload'
export const UPLOAD_IMAGE_CDN = 'uploadImageCdn'
export const UPLOAD_BULK_IMAGE_CDN = 'uploadBulkImageCdn'
export const UPLOAD_MERCHANT_IMAGE_CDN = 'uploadMerchantImageCdn'
export const UPLOAD_MERCHANT_BULK_IMAGE_CDN = 'uploadMerchantBulkImageCdn'
export const UPDATE_MERCHANT_IMAGE_CDN = 'updateMerchantImageCdn'
export const DELETE_MERCHANT_IMAGE_CDN = 'deleteMerchantImageCdn'
export const GET_MERCHANT_IMAGE_LIST_CDN = 'getMerchantImageListCdn'
export const GET_MERCHANT_IMAGE_CDN_BY_ID = 'getMerchantImageCdnById'
export const GET_MERCHANT_BULK_IMAGE_LIST_CDN = 'getMerchantBulkImageListCdn'

export const SET_SELECTED_IMAGE_FOR_UPLOAD = 'setSelectedImageForUpload'
export const SET_MERCHANT_IMAGE_LIST = 'setMerchantImageList'
export const SET_MERCHANT_IMAGE_SINGLE = 'setMerchantImageSingle'
export const RESET_SELECTED_IMAGE_FOR_UPLOAD = 'resetSelectedImageForUpload'

export default {
  namespaced: true,
  state: {
    selectedImageForUpload: null,
    merchantImageList: null,
    merchantImageSingle: null,
  },
  getters: {
    getSelectedImageForUpload: state => {
      const list = state.selectedImageForUpload
      if (Array.isArray(list)) {
        return list
      }
      return []
    },
    getMerchantImageList: state => {
      const list = state.merchantImageList
      if (Array.isArray(list)) {
        return list
      }
      return []
    },
  },
  mutations: {
    [SET_SELECTED_IMAGE_FOR_UPLOAD](state, val) {
      state.selectedImageForUpload = val
    },
    [SET_MERCHANT_IMAGE_LIST](state, val) {
      state.merchantImageList = val
    },
    [SET_MERCHANT_IMAGE_SINGLE](state, val) {
      state.merchantImageSingle = val
    },
    [RESET_SELECTED_IMAGE_FOR_UPLOAD](state) {
      state.selectedImageForUpload = null
    },
  },
  actions: {
    [SELECT_IMAGE_FOR_UPLOAD](context, payload) {
      context.commit(SET_SELECTED_IMAGE_FOR_UPLOAD, payload)
    },
    [UPLOAD_IMAGE_CDN](context, payload) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}cdn-digital-ocean/upload-cdn-do`, payload, config)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              // useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPLOAD_BULK_IMAGE_CDN](context, payload) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}cdn-digital-ocean/bulk-upload-cdn-do`, payload, config)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              // useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [UPLOAD_MERCHANT_IMAGE_CDN](context, payload) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-cloud-image`, payload, config)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              // useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPLOAD_MERCHANT_BULK_IMAGE_CDN](context, payload) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-cloud-image/bulk-create-cloud-image`, payload, config)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              // useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_MERCHANT_IMAGE_CDN](context, payload) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-cloud-image/${payload.id}`, payload, config)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              // useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_MERCHANT_IMAGE_CDN](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-cloud-image/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              // useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_IMAGE_CDN_BY_ID](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-cloud-image/merchant-cloud-image/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              // useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_IMAGE_SINGLE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_MERCHANT_IMAGE_LIST_CDN](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-cloud-image/merchant-cloud-image-records?skip=0&limit=100`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              // useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_IMAGE_LIST, res.data.data.list.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_BULK_IMAGE_LIST_CDN](context, payload) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-cloud-image/bulk-create-cloud-image`, payload, config)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              // useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
