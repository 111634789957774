import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import SystemHelper from '@/common/systemHelper'
import useJwt from '@/auth/jwt/useJwt'

export const GET_LIST_VERIFICATION_CHANNEL = 'getListVerificationChannel'
export const SET_LIST_VERIFICATION_CHANNEL = 'setListVerificationChannel'
export const VERIFY_BANK_ACCOUNT = 'verifyBankAccount'
export const GET_VERIFICATION_PAYLOAD = 'getVerificationPayload'
export const UPDATE_VERIFICATION_PAYLOAD = 'updateVerificationPayload'
export const OMNI_CALLBACK_BULK_CALLBACK = 'omniCallbackBulkCallback'
export const SET_VERIFICATION_PAYLOAD = 'setVerificationPayload'
export const TOGGLE_OMNI_SEARCH_MODAL = 'toggleOmniSearchModal'
export const SET_TOGGLE_OMNI_SEARCH_MODAL = 'setToggleOmniSearchModal'

export const TOGGLE_OMNI_MULTI_SEARCH_MODAL = 'toggleOmniMultiSearchModal'
export const SET_TOGGLE_OMNI_MULTI_SEARCH_MODAL = 'setToggleOmniMultiSearchModal'

export const SET_OMNI_CHECK_INVOICE = 'setOmniCheckInvoice'
export const OMNI_CHECK_INVOICE = 'omniCheckInvoice'

export const GET_ONBOARD_PAYOUT_LIST = 'getOnboardPayoutList'
export const SET_ONBOARD_PAYOUT_LIST = 'setOnboardPayoutList'

export const TRIGGER_PAYOUT = 'triggerPayout'

export const GET_TESTER_PAYOUT_POOL_BALANCE = 'getTesterPayoutPoolBalance'
export const SET_TESTER_PAYOUT_POOL_BALANCE = 'setTesterPayoutPoolBalance'

const ONBOARD_API_SEGMENT = '/api/v1/env'
const ONBOARD_MOBILE_SEGMENT = '/mobile'
const ONBOARD_SYSTEM_TYPE = '/superadmin'

console.log(APP_SETTINGS.ONBOARD_BASE_URL)

export default {
  namespaced: true,
  state: {
    verificationChannelList: null,
    verificationPayload: null,
    showOmniSearchModal: false,
    omniCheckInvoiceData: false,
    showOmniMultiSearchModal: false,
    onboardPayoutList: [],
    testerPayoutPoolBalance: null,
  },
  getters: {

    getOmniSearchModalStatus(state) {
      return state.showOmniSearchModal
    },
    verificationChannelListOpts: state => {
      const list = state.verificationChannelList

      if (Array.isArray(list) && list.length > 0) {
        const opts = list.map(v => ({
          payout_service_id: v.payout_service_id,
          name: v.name,
        }))
        return opts
      } return []
    },
    getPayoutList: state => {
      const list = state.onboardPayoutList
      console.log(list)
      return list

      if (Array.isArray(list) && list.length > 0) {
        // const opts = list.map(v => ({
        //   payout_service_id: v.payout_service_id,
        //   name: v.name,
        // }))
        // return opts
        return list
      } return []
    },
    getTestPayoutPoolBalanceDetail: state => {
      const list = state.testerPayoutPoolBalance

      if (list) {
        return list
      } return {
        prefund_balance: 0,
        transaction_balance: {
          value: 0,
        },
        total_transaction_as_of_today: 0,

      }
    },
  },
  mutations: {
    [SET_LIST_VERIFICATION_CHANNEL](state, val) {
      state.verificationChannelList = val
    },
    [SET_VERIFICATION_PAYLOAD](state, val) {
      state.verificationPayload = val
    },
    [SET_TOGGLE_OMNI_SEARCH_MODAL](state, val) {
      state.showOmniSearchModal = val
    },
    [SET_TOGGLE_OMNI_MULTI_SEARCH_MODAL](state, val) {
      state.showOmniMultiSearchModal = val
    },
    [SET_OMNI_CHECK_INVOICE](state, val) {
      state.omniCheckInvoiceData = val
    },
    [SET_ONBOARD_PAYOUT_LIST](state, val) {
      state.onboardPayoutList = val
    },
    [SET_TESTER_PAYOUT_POOL_BALANCE](state, val) {
      state.testerPayoutPoolBalance = val
    },

  },
  actions: {

    [GET_VERIFICATION_PAYLOAD]() {
      return this.state.verificationPayload
    },
    [UPDATE_VERIFICATION_PAYLOAD](state, payload) {
      state.commit(SET_VERIFICATION_PAYLOAD, payload)
    },
    [TOGGLE_OMNI_SEARCH_MODAL](state, payload) {
      state.commit(SET_TOGGLE_OMNI_SEARCH_MODAL, payload)
    },
    [TOGGLE_OMNI_MULTI_SEARCH_MODAL](state, payload) {
      state.commit(SET_TOGGLE_OMNI_MULTI_SEARCH_MODAL, payload)
    },
    [GET_LIST_VERIFICATION_CHANNEL](context) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/list-verification-channel`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              context.commit(SET_LIST_VERIFICATION_CHANNEL, res.data.data.list.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ONBOARD_PAYOUT_LIST](context) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/GetPayoutList`)
          .then(res => {
            if (res.data.response_code === 2100) {
              // console.log(res.data)
              context.commit(SET_ONBOARD_PAYOUT_LIST, res.data.data.response.data.list)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            // SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_TESTER_PAYOUT_POOL_BALANCE](context) {
      return new Promise((resolve, reject) => {
        // https://onboard-api.leanx.io/api/v1/env/mobile/superadmin/GetPoolBalance
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/GetPoolBalance`)
          .then(res => {
            if (res.data.response_code === 2100) {
              // console.log(res.data)
              context.commit(SET_TESTER_PAYOUT_POOL_BALANCE, res.data.data.response.data)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            // SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [TRIGGER_PAYOUT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/TriggerPayout`, payload)
          .then(res => {
            if (res.data.response_code === 2100) {
              // console.log(res.data)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            // SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [OMNI_CHECK_INVOICE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}admin-helper/omni-check-invoice`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              context.commit(SET_OMNI_CHECK_INVOICE, res.data.data.result)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [VERIFY_BANK_ACCOUNT](context, payload) {
      /// api/v1/payment-services/switch/verify-bank-account
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/verify-bank-account`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [OMNI_CALLBACK_BULK_CALLBACK](context, payload) {
      /// api/v1/payment-services/switch/verify-bank-account
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}admin-helper/omni-callback-data/bulk-send-callback`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

  },
}
