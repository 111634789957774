import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_DOMAINS = 'getDomains'
export const GET_DOMAINS_REDBIRD = 'getDomainsRedbird'
export const SET_DOMAINS = 'setDomainsList'

export default {
  namespaced: true,
  state: {
    domainsList: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfo: state => {
      const list = state.domainsList
      if (list) {
        return list
      }
      return []
    },
    activeDomainsList: state => {
      const currentState = state.domainsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    domainsList: state => {
      const currentState = state.domainsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_DOMAINS](state, val) {
      state.domainsList = val
    },
  },
  actions: {

    [GET_DOMAINS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}domains`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_DOMAINS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_DOMAINS_REDBIRD](context) {
      return new Promise((resolve, reject) => {
        axiosIns.get('https://short.leanpay.my/api/v1/redirect?page=1&size=50', {
          headers: {
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDY2MDMyNzgsImlkIjoiMSIsInJvbGUiOiJTdXBlckFkbWluIiwiYWNjb3VudF9pZCI6Ik5vbmUifQ.BHivvQbk66dxt4aYCyTmf3E2476RCsiXb6OXuPsXpDs',
          },
        })
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_DOMAINS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
