import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const PING_REDIS = 'pingRedis'
export const REFRESH_REDIS = 'refreshRedis'
export const SET_PING_REDIS = 'setPingRedis'
export const SET_REFRESH_REDIS = 'setRefreshRedis'
export const TRIGGER_ADJUSTMENT_MASTER_POOL_BALANCE = 'triggerAdjustmentMasterPoolBalance'
export const TRIGGER_FAILED_BILL_ADJUSTMENT = 'triggerFailedBillAdjustment'
export const CHANGE_MIN_LIMIT_SELF_SETTLEMENT = 'changeMinLimitSelfSettlement'
export const ADMIN_REFUND_TO_PREFUND = 'adminRefundToPrefund'
export const ADMIN_TAKE_OUT_FROM_PREFUND = 'adminTakeOutToPrefund'
export const ADMIN_UPDATE_PREFUND_SETTINGS = 'adminUpdatePrefundSettings'

export default {
  namespaced: true,
  state: {
    pingRedis: null,
    refreshRedis: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfoByStateKey: state => stateKey => {
      console.log('here', state[stateKey])
      console.log('here', state)
      console.log('here', stateKey)

      const list = state[stateKey]
      if (list) {
        return list.list
      }
      return []
    },
    pingRedisResult: state => {
      const currentState = state.pingRedis

      if (currentState) {
        const list = currentState
        return list
      }
      return null
    },
    refreshRedisResult: state => {
      const currentState = state.refreshRedis

      if (currentState) {
        const list = currentState
        return list
      }
      return null
    },
    getPaginationInfo: state => {
      const list = state.customerBillsList
      if (list) {
        return list
      }
      return []
    },
  },
  mutations: {
    [SET_PING_REDIS](state, val) {
      state.pingRedis = val
    },
    [SET_REFRESH_REDIS](state, val) {
      state.refreshRedis = val
    },
  },
  actions: {
    [PING_REDIS](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/admin-helper/ping-redis
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}admin-helper/ping-redis`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              context.commit(SET_PING_REDIS, res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [REFRESH_REDIS](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/admin-helper/clear-redis-data
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}admin-helper/clear-redis-data`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              context.commit(SET_REFRESH_REDIS, res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [TRIGGER_ADJUSTMENT_MASTER_POOL_BALANCE](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/admin-helper/clear-redis-data
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}jwt/adjustment/master-pool-balances`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [TRIGGER_FAILED_BILL_ADJUSTMENT](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/admin-helper/clear-redis-data
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/failed-adjustment/${payload.billId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CHANGE_MIN_LIMIT_SELF_SETTLEMENT](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/admin-helper/clear-redis-data
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/admin/update-minimum-self-settlement/${payload.id}?amount=${payload.amount}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [ADMIN_REFUND_TO_PREFUND](context, payload) {
      return new Promise((resolve, reject) => {
        // https://api.leanx.io/api/v1/admin-helper/refund-to-prefund
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}admin-helper/refund-to-prefund`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [ADMIN_TAKE_OUT_FROM_PREFUND](context, payload) {
      return new Promise((resolve, reject) => {
        // https://api.leanx.io/api/v1/admin-helper/refund-to-prefund
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}admin-helper/takeout-from-prefund`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [ADMIN_UPDATE_PREFUND_SETTINGS](context, payload) {
      return new Promise((resolve, reject) => {
        // https://api.leanx.io/api/v1/accounts/admin/update-prefund-settings/12
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/admin/update-prefund-settings/${payload.accountId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },

}
